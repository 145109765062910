import RealizedAndPlannedComponent from "../page/report/realized-and-planned/realized-and-planned.component";
import UsageProductionLines from "../page/report/usage-production-lines/usage-production-lines";
import ReasonReplanningReportComponent from "../page/reports/reason-replanning-report.component";
import React from "react";
import DemandUsedHoursReportComponent from "../page/report/demand-used-hours/demand-used-hours-report.component";
import DowntimeRankingReportComponent from "../page/report/downtime-ranking-report/downtime-ranking-report.component";
import DowntimeSummaryByQuantityReportComponent from "../page/report/downtime-by-quantity-report/downtime-by-quantity-report";
import DowntimeSummaryByAvailableTimeReportComponent from "../page/report/downtime-by-available-time-report/downtime-by-available-time-report";
import ProducedQuantityComponent from "../page/report/produced-quantity/produced-quantity.component";
import OeeComponent from "../page/report/oee/oee.component";
import DashboardOeeComponent from "../page/dashboard/dashboard-oee/dashboard-oee.component";
import DashboardLineScheduleComponent from "../page/dashboard/line-schedule/line-schedule.component";
import DashboardBufferChartComponent from "../page/dashboard/buffer-chart/buffer-chart.component";
import DashboardWipVolumeComponent from "../page/dashboard/wip-volume/wip-volume.component";
import ImportLineScheduleComponent from "../page/dashboard/import-line-schedule/import-line-schedule.component";
import DashboardUphComponent from "../page/dashboard/uph/uph.component";
import DashboardUpphComponent from "../page/dashboard/upph/upph.component";
import DashboardUpphProductionLineComponent from "../page/dashboard/upph-per-production-line/upph-per-production-line.component";

type ReportsComponentMap = {
    [key: string]: React.FC;
};

const reportsComponentMap: ReportsComponentMap = {
    "/reports/reason_replanning_report": ReasonReplanningReportComponent,
    "/reports/demand": DemandUsedHoursReportComponent,
    "/reports/realized_vs_planned": RealizedAndPlannedComponent,
    "/reports/production_line_usage": UsageProductionLines,
    "/reports/downtime_ranking": DowntimeRankingReportComponent,
    "/reports/downtime_summary_by_quantity_report": DowntimeSummaryByQuantityReportComponent,
    "/reports/downtime_summary_by_available_time_report": DowntimeSummaryByAvailableTimeReportComponent,
    "/reports/produced_quantity": ProducedQuantityComponent,
    "/reports/oee": OeeComponent,
    "/dashboard/dashboard_oee": DashboardOeeComponent,
    "/dashboard/line_schedule": DashboardLineScheduleComponent,
    "/dashboard/buffer_chart": DashboardBufferChartComponent,
    "/dashboard/wip_volume": DashboardWipVolumeComponent,
    "/dashboard/import_line_schedule": ImportLineScheduleComponent,
    "/dashboard/uph": DashboardUphComponent,
    "/dashboard/upph": DashboardUpphComponent,
    "/dashboard/upph_production_line": DashboardUpphProductionLineComponent,
};

export default reportsComponentMap;