import React, {useEffect, useState} from "react";
import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import {ErrorComponent} from "../../../../components/error/error.component";
import {BaseService} from "../../../../services/base-service";
import CustomReportDetailComponent from "./custom-report-detail.component";
import {useKeepFilters} from "../../../../utils/form-utils";
import {CustomReportConfig} from "../../../../models/basic/custom-report-config";
import {useAuth} from "../../../../contexts/auth-context";

const CustomReportConfigItemComponent = () => {
    const params = useParams();
    const url = "/registration/custom_report_config/";
    const navigate = useNavigate();
    const service = new BaseService<CustomReportConfig>(URLS.CUSTOM_REPORT_CONFIG);
    const [object, setObject] = React.useState<CustomReportConfig>(new CustomReportConfig());
    const [buttonValue, setButtonValue] = useState("");

    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;
    const {user} = useAuth();

    const schema = Yup.object().shape({
        name: Yup.string().required(translate.t("required_field")),
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    const resetFields = (resetForm: any) => {
        setObject(new CustomReportConfig());
        resetForm();
    };

    const onSubmit = (values: CustomReportConfig, {resetForm}: FormikHelpers<CustomReportConfig>) => {
        setTimeout(async () => {
            const object = new CustomReportConfig();
            Object.assign(object, values);
            object.user = user.url;
            if (object.id) {
                service.patch(object, object.id).then(() => {
                    toast.success(translate.t("successfully_updated"));
                    if (buttonValue === "save") {
                        resetFields(resetForm);
                        navigate(`${url}create`);
                    } else {
                        navigate(url);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            } else {
                service.save(object)
                    .then(() => {
                        toast.success(translate.t("successfully_registered"));
                        if (buttonValue === "save") {
                            resetFields(resetForm);
                        } else {
                            navigate(url);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }, 500);
    };

    return (
        <section>
            <div className="floating-title floating-title-item"
                style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("custom_report")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 104,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
            {
                params["action"] != "create" &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                }}>
                    <CustomReportDetailComponent customReportConfig={object}/>
                </Box>
            }

        </section>
    );
};

export default CustomReportConfigItemComponent;
