import * as React from "react";
import {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {translate} from "../../translate/translate";
import {ErrorComponent} from "../error/error.component";

interface Element {
    [key: string]: any;
}

const AutocompletePaginated = (props: any) => {
    const {
        keepFilter,
        display,
        searchField,
        label,
        service,
        onSelectElement,
        element,
        required = false,
        disabled = false,
        clear = false,
        reset = false,
        filters = [],
    } = props;
    const limit = 5;
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);
    const [elements, setElements] = useState<Element[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showPaginationButtons, setShowPaginationButtons] = useState(false);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [initialValueSet, setInitialValueSet] = useState(false);
    const [focusAutocomplete, setFocusAutocomplete] = useState(false);
    const [internalReset, setInternalReset] = useState(false);

    useEffect(() => {
        if (keepFilter) {
            service.getById(keepFilter).then((response: any) => {
                setSelectedOption(response.data);
            });
        }
    }, []);

    useEffect(() => {
        if (element && !initialValueSet) {
            setSelectedOption(element);
            setElements([element]);
            setInitialValueSet(true);
        }
    }, [element, initialValueSet]);

    useEffect(() => {
        getElements();
    }, [offset, searchTerm]);

    useEffect(() => {
        setSelectedOption(null);
    }, [clear]);

    useEffect(() => {
        if (reset) {
            setInternalReset(true);
            setSelectedOption(null);
            setElements([]);
            setSearchTerm("");
            setOffset(0);
            setInitialValueSet(false);
            setFocusAutocomplete(false);
            setTimeout(() => setInternalReset(false), 500);
        }
    }, [reset]);

    const getElements = () => {
        service.clearParameters();
        filters.forEach((filter: any) => {
            const keys = Object.keys(filter);
            if (keys.length > 0) {
                keys.forEach((key) => {
                    service.addParameter(key, filter[key]);
                });
            }
        });
        if (searchTerm) {
            service.addParameter(searchField, searchTerm);
        }
        service.addParameter("limit", limit);
        service.addParameter("offset", offset);
        service.getAllPaginate().then((response: any) => {
            setCount(response.data.count);
            setElements(response.data.results);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const previous = () => {
        if (offset > 0) {
            setOffset(offset - limit);
        }
    };

    const next = () => {
        if ((offset + limit) < count) {
            setOffset(offset + limit);
        }
    };

    const handleAutocompleteChange = (event: React.ChangeEvent<any>, value: string | null) => {
        setSelectedOption(value);
        setOffset(0);
        setSearchTerm("");
        onSelectElement(value);
    };

    const displayValue = (option: any) => {
        const displays = display instanceof Array ? display : [display];
        const values = displays.map((item) => option[item] ? translate.t(option[item].toString()) : "");
        return values.length > 1 ? values.join(" - ") : values[0];
    };

    return (
        <Autocomplete
            disabled={disabled}
            onOpen={() => setShowPaginationButtons(true)}
            onClose={() => setShowPaginationButtons(false)}
            value={selectedOption}
            isOptionEqualToValue={(option, value) => {
                return option[display] === value[display];
            }}
            getOptionLabel={(option) => displayValue(option)}
            closeText={translate.t("close")}
            openText={translate.t("open")}
            clearText={translate.t("clear")}
            noOptionsText={translate.t("empty_listing")}
            options={elements}
            onChange={handleAutocompleteChange}
            onChangeCapture={() => setOffset(0)}
            onBlur={() => setFocusAutocomplete(true)}
            onInputChange={(event, newValue) => {
                if (selectedOption && !newValue.includes(selectedOption?.[display] ? selectedOption[display] : "")) {
                    setSelectedOption(null);
                }
                setSearchTerm(newValue);
            }}
            className={(focusAutocomplete && !selectedOption && required) ? "error-label-autocomplete" : ""}
            renderInput={(params) => <TextField
                className={"autocomplete-paginated"}
                sx={{
                    marginTop: 0,
                    marginBottom: "3px",
                }}
                {...params}
                margin="normal"
                label={translate.t(label)}
                required={required}
                disabled={disabled}
                InputProps={{
                    ...params.InputProps,
                    className: (focusAutocomplete && !selectedOption && required && !internalReset) ? "error-autocomplete" : "",
                    endAdornment: (
                        <React.Fragment>
                            {
                                <div
                                    className={
                                        (!showPaginationButtons || (!offset && (offset + limit) >= count)) ? "hide-buttons" : selectedOption ? "pagination-buttons-select" : "pagination-buttons"
                                    }
                                    onClick={(e) => e.stopPropagation()}>
                                    <IconButton disabled={!offset} sx={{padding: "0 0 3px 0"}} onClick={() => previous()}>
                                        <ChevronLeft/>
                                    </IconButton>
                                    <IconButton disabled={(offset + limit) >= count} sx={{padding: "0 0 3px 0"}} onClick={() => next()}>
                                        <ChevronRight/>
                                    </IconButton>
                                </div>
                            }
                            {(focusAutocomplete && !selectedOption && required && !internalReset) && (
                                <div className={"required-field"}>
                                    {translate.t("required_field")}
                                </div>
                            )}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
            }/>
    );
};

AutocompletePaginated.displayName = "AutocompletePaginated";

export default AutocompletePaginated;
