import {BottomNavigation, BottomNavigationAction, Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, InputAdornment, Paper, Switch, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Add, Close, Groups} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useAuth} from "../../../../contexts/auth-context";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {User} from "../../../../models/account/user";
import {translate} from "../../../../translate/translate";
import ChangePasswordComponent from "../../../../components/change-password-dialog/change-password.component";
import {UserGroupComponent} from "./user-group.component";
import {ErrorComponent} from "../../../../components/error/error.component";
import {useKeepFilters} from "../../../../utils/form-utils";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const UserItemComponent = () => {
    const service = new BaseService<User>(URLS.USER);
    const [object, setObject] = React.useState(new User());
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openProceed, setOpenProceed] = React.useState(false);
    const {user} = useAuth();
    const [is_superuser, setIsSuperUser] = React.useState(false);
    const [buttonValue, setButtonValue] = useState("");
    const [displayContent, setDisplayContent] = useState(true);
    const isCurrentUser = object.id === user?.id;
    const params = useParams();
    const navigate = useNavigate();
    const url = "/account/user/";
    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        name: Yup.string()
            .required(),
        email: Yup.string()
            .required(" ")
            .email(translate.t("invalid_email")),
        username: Yup.string()
            .required(),
        password: Yup.string().when([], {
            is: () => !object.id,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        }),
        confirmPassword: Yup.string().when([], {
            is: () => !object.id,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired(),
        }),
        is_superuser: Yup.boolean()
    });

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleOpenDialogProceed = () => {
        setOpenProceed(true);
    };

    const handleCloseDialogProceed = () => {
        setOpenProceed(false);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const newUrl = (value: any) => {
        const urlBase = `${url}${value}`;
        navigate(urlBase);
        window.history.replaceState(null, "", urlBase);
    };

    const handleSubmit = (values: any, {resetForm}: FormikHelpers<User>) => {
        service.clearParameters();
        const user = new User();
        Object.assign(user, values);
        user.is_superuser = is_superuser;
        if (user.id) {
            try {
                service.patch(user, user.id).then((response) => {
                    toast.success(translate.t("successfully_updated"));
                    if (buttonValue === "save") {
                        setObject(new User());
                        resetForm();
                        setIsSuperUser(false);
                        setDisplayContent(false);
                    } else {
                        setObject(response.data);
                        setDisplayContent(true);
                        newUrl(response.data.id);
                        navigate(previousUrl);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                service.save(user)
                    .then(response => {
                        toast.success(translate.t("successfully_updated"));
                        if (buttonValue === "save") {
                            setObject(new User());
                            resetForm();
                            setIsSuperUser(false);
                            setDisplayContent(false);
                        } else {
                            setObject(response.data);
                            setDisplayContent(true);
                            newUrl(response.data.id);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };

    function onSubmit(values: any, resetForm: any) {
        service.clearParameters();
        if (params["action"] == "create") {
            if (values.password !== values.confirmPassword) {
                toast.error(translate.t("password_not_match"));
            } else {
                handleSubmit(values, resetForm);
            }
        } else {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
            handleSubmit(values, resetForm);
        }
    }

    useEffect(() => {
        if (params["action"] !== "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setIsSuperUser(response.data.is_superuser);
            });
        }
    }, []);

    const handleChangeSuperUser = (newValue: boolean) => {
        setIsSuperUser(newValue);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("user")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmit}
                            validationSchema={schema}>
                            {
                                ({isValid, values, isValidating, errors, touched}) => (
                                    <Form autoComplete="off">
                                        <Grid container gap={2} direction={"column"}>
                                            <Grid item container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        name="username"
                                                        label={translate.t("username")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={true}
                                                        required={true}
                                                        values={values}
                                                        error={touched.username && Boolean(errors.username)}
                                                        InputProps={{
                                                            maxLength: 64,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.username && Boolean(errors.username) && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}/>
                                                </Grid>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        name="name"
                                                        label={translate.t("name")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={false}
                                                        required={true}
                                                        values={values}
                                                        error={touched.name && Boolean(errors.name)}
                                                        InputProps={{
                                                            maxLength: 256,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.name && Boolean(errors.name) && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}/>
                                                </Grid>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        name="email"
                                                        label={translate.t("email")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={false}
                                                        required={true}
                                                        values={values}
                                                        type={"email"}
                                                        error={touched.email && Boolean(errors.email)}
                                                        InputProps={{
                                                            maxLength: 256,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {touched.email && Boolean(errors.email) && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }}/>
                                                </Grid>
                                            </Grid>
                                            {!object.id &&
                                                <Grid item container spacing={2} direction={"row"}>
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Field
                                                            as={TextField}
                                                            name="password"
                                                            label={translate.t("password")}
                                                            variant={"outlined"}
                                                            fullWidth
                                                            autoFocus={false}
                                                            required={true}
                                                            values={values}
                                                            type={"password"}
                                                            error={touched.password && Boolean(errors.password)}
                                                            InputProps={{
                                                                maxLength: 104,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {touched.password && Boolean(errors.password) && (
                                                                            <div className={"required-field"}>
                                                                                {translate.t("required_field")}
                                                                            </div>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Field
                                                            as={TextField}
                                                            name="confirmPassword"
                                                            label={translate.t("confirmPassword")}
                                                            variant={"outlined"}
                                                            fullWidth
                                                            autoFocus={false}
                                                            required={true}
                                                            values={values}
                                                            type={"password"}
                                                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                                            InputProps={{
                                                                maxLength: 104,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {touched.confirmPassword && Boolean(errors.confirmPassword) && (
                                                                            <div className={"required-field"}>
                                                                                {translate.t("required_field")}
                                                                            </div>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}/>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item container justifyContent={"space-between"}>
                                                <Grid item xs alignItems={"center"}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                type="checkbox"
                                                                name="is_superuser"
                                                                component={Switch}
                                                                checked={is_superuser}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                    handleChangeSuperUser(e.target.checked)}
                                                                color="secondary"
                                                                disabled={isCurrentUser}
                                                            />
                                                        }
                                                        label={translate.t("superUser")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    container item xs={12} spacing={1}
                                                    justifyContent={"flex-end"}>
                                                    <Grid item>
                                                        <Button
                                                            color="secondary"
                                                            component={NavLink}
                                                            to={previousUrl}
                                                            variant="outlined">
                                                            {translate.t("cancel")}
                                                        </Button>
                                                    </Grid>
                                                    {object.id &&
                                                        <Grid item>
                                                            <Button
                                                                color="secondary"
                                                                variant="outlined"
                                                                onClick={() => handleOpenDialog()}>
                                                                {translate.t("redefine_password")}
                                                            </Button>
                                                        </Grid>}
                                                    <Grid item>
                                                        <Button
                                                            color="success"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save_form"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save_form"
                                                            disabled={!isValid || isValidating || !values.name}>
                                                            {translate.t("save")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save"
                                                            disabled={!isValid || isValidating || !values.name}>
                                                            {translate.t("save")}
                                                            <Add/>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
                {displayContent ? (
                    <div>
                        {params["action"] != "create" &&
                            <Box sx={{width: "100%"}}>
                                <BottomNavigation
                                    sx={{
                                        marginTop: "12px",
                                        borderBottom: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        backgroundColor: "#F0F3F6"
                                    }}
                                    showLabels
                                    value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <BottomNavigationAction
                                        label={translate.t("group")}
                                        icon={<Groups/>}
                                        sx={{
                                            borderTopLeftRadius: "7px",
                                            borderTopRightRadius: "7px",
                                            backgroundColor: "#fff",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        {...a11yProps(0)}/>
                                </BottomNavigation>
                            </Box>
                        }
                        {object.id ? <UserGroupComponent userId={object.id}/> : null}
                    </div>
                ) : null}
                <Dialog open={open} onClose={handleCloseDialog}>
                    <Grid container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                        <Grid item m={1}>
                            <Typography color={"#ffffff"}>
                                {translate.t("confirm_recover_password_tittle")}
                            </Typography>
                        </Grid>
                        <Grid item m={1} sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => {
                                handleCloseDialog();
                            }}/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid container flexDirection="column" gap={3}>
                            <Grid item>
                                <Typography>
                                    {translate.t("confirm_recover_password_text")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="flex-end" gap={2}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        handleCloseDialog();
                                    }}>
                                    {translate.t("cancel")}</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleOpenDialogProceed();
                                        handleCloseDialog();
                                    }}>
                                    {translate.t("proceed")}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <ChangePasswordComponent
                    openProceed={openProceed} object={object}
                    handleCloseDialogProceed={handleCloseDialogProceed}/>
            </div>
        </section>
    );

};

export default UserItemComponent;
