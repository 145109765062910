import {BaseService} from "../../../../services/base-service";
import {Group} from "../../../../models/account/group";
import {BottomNavigation, BottomNavigationAction, Box, Button, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import {AccountCircle, Add, Check} from "@mui/icons-material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import GroupUserComponent from "./group-user.component";
import {GroupPermissionComponent} from "./group-permission.component";
import {GroupModuleComponent} from "./group-module.component";
import {useKeepFilters} from "../../../../utils/form-utils";


const GroupItemComponent = () => {
    const [object, setObject] = React.useState(new Group());
    const service = new BaseService<Group>(URLS.GROUP);
    const params = useParams();
    const url = "/account/group/";
    const [selectedTab, setSelectedTab] = useState(0);
    const [buttonValue, setButtonValue] = useState("");
    const [displayContent, setDisplayContent] = useState(params["action"] != "create");
    const navigate = useNavigate();

    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        name: Yup.string().required()
    });

    const newUrl = (value: any) => {
        const urlBase = `${url}${value}`;
        navigate(urlBase);
        window.history.replaceState(null, "", urlBase);
    };

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center", top: "-52px"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("group")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: Group, {resetForm}: FormikHelpers<Group>) => {
                                setTimeout(() => {
                                    const group = new Group();
                                    Object.assign(group, values);
                                    if (group.id) {
                                        try {
                                            service.patch(group, group.id).then((response) => {
                                                toast.success(translate.t("successfully_updated"));
                                                if (buttonValue === "save") {
                                                    setObject(new Group());
                                                    resetForm();
                                                    setDisplayContent(false);
                                                } else {
                                                    setObject(response.data);
                                                    setDisplayContent(true);
                                                    newUrl(response.data.id);
                                                    navigate(previousUrl);
                                                }
                                            }).catch((error) => {
                                                if (error.response && error.response.status === 400) {
                                                    const message_error =
                                                        error.response.data.name || "error_required_field";
                                                    toast.error(translate.t(message_error));
                                                } else {
                                                    console.log(error);
                                                }
                                            });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        try {
                                            service.save(group)
                                                .then(response => {
                                                    toast.success(translate.t("successfully_registered"));
                                                    if (buttonValue === "save") {
                                                        setObject(new Group());
                                                        resetForm();
                                                        setDisplayContent(false);
                                                    } else {
                                                        setObject(response.data);
                                                        setDisplayContent(true);
                                                        newUrl(response.data.id);
                                                    }
                                                })
                                                .catch((error) => {
                                                    if (error.response && error.response.status === 400) {
                                                        const message_error =
                                                            error.response.data.name ?
                                                                "invalid_group" : "error_required_field";
                                                        toast.error(translate.t(message_error));
                                                    } else {
                                                        console.log(error);
                                                    }
                                                });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 150,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        startIcon={<Check/>}
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        startIcon={<Add/>}
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
                {displayContent ? (
                    <div>
                        {params["action"] != "create" &&

                            <BottomNavigation
                                sx={{
                                    gap: "8px",
                                    marginTop: "16px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    backgroundColor: "#F0F3F6",
                                }}
                                showLabels
                                value={selectedTab}
                                onChange={handleTabChange}>

                                <BottomNavigationAction
                                    label={translate.t("user")}
                                    icon={<AccountCircle/>}
                                    sx={{
                                        borderTopLeftRadius: "4px",
                                        borderTopRightRadius: "4px",
                                        backgroundColor: "#fff"
                                    }}/>

                                <BottomNavigationAction
                                    label={translate.t("module")}
                                    icon={<ViewModuleIcon/>}
                                    sx={{
                                        borderTopLeftRadius: "4px",
                                        borderTopRightRadius: "4px",
                                        backgroundColor: "#fff"
                                    }}/>

                                <BottomNavigationAction
                                    label={translate.t("permission")}
                                    icon={<LockOpenIcon/>}
                                    sx={{
                                        borderTopLeftRadius: "4px",
                                        borderTopRightRadius: "4px",
                                        backgroundColor: "#fff"
                                    }}/>

                            </BottomNavigation>}
                        {selectedTab === 0 ? <GroupUserComponent groupId={object.id}/> : selectedTab === 1 ?
                            <GroupModuleComponent groupId={object.id}/> :
                            <GroupPermissionComponent groupId={object.id}/>}
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default GroupItemComponent;
