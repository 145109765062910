import BaseModel from "../default/base-model";

export class CustomReportDetail extends BaseModel {
    name: string;
    url: string;
    order: number;
    custom_report_config: string;

    constructor() {
        super();
        this.name = "";
        this.url = "";
        this.order = 0;
        this.custom_report_config = "";
    }
}
