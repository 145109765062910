import "./dialog-shortage.component.css";
import {Box, Dialog, DialogContent, DialogTitle, Fab, Grid, Typography} from "@mui/material";
import {translate} from "../../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import {Close, Search} from "@mui/icons-material";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import Loading from "../../../../components/loading/loading";
import {SpreadsheetImportItem} from "../../../../models/planning/spreadsheet-import-item";
import {ErrorComponent} from "../../../../components/error/error.component";
import {Form, Formik} from "formik";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../../models/basic/product";


interface product {
    product: string;
    product_id: number;
    quantities: number[];
}

interface resultData {
    days: string[];
    products: product[];
    count: number;
}

const DialogShortageComponent = (props: any) => {
    const {open, setOpen, onClose} = props;
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const service = new BaseService<SpreadsheetImportItem>(URLS.SPREADSHEET_IMPORT_ITEM);
    const [dataSource, setDataSource] = React.useState<resultData>();
    const [loading, setLoading] = React.useState(false);
    const [searchProduct, setSearchProduct] = useState<Product>();

    const search = () => {
        setLoading(true);
        service.clearParameters();
        if (searchProduct) service.addParameter("product", searchProduct.id);
        service.getFromListRoute("get_shortage").then((response: any) => {
            setDataSource(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        search();
    }, []);

    const formatDate = (date: string) => {
        return date.split("-")[2].padStart(2, "0") + "/" + date.split("-")[1].padStart(2, "0") + "/" + date.split("-")[0];
    };

    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose();
        setOpen(false);
    };

    return (

        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xl"}>
            <DialogTitle sx={{backgroundColor: "primary.main"}}>
                <Grid container direction="row">
                    <Grid item xs>
                        <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                            {translate.t("Shortage")}
                        </Typography>
                    </Grid>
                    <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                        <Close onClick={() => setOpen(false)}/>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Box minHeight={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} sx={{padding: "10px"}}>
                    <Grid container spacing={2} direction={"row"}>
                        <Grid item sm>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{name: ""}}
                                onSubmit={(values: any, {setSubmitting}) => {
                                    setTimeout(() => {
                                        search();
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    () => (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item sm={4}>
                                                    <AutocompletePaginated
                                                        display={["model", "name"]}
                                                        searchField="full_description"
                                                        label="product"
                                                        service={productService}
                                                        filters={[{"shortage": true}]}
                                                        onSelectElement={(product: Product) => {
                                                            setSearchProduct(product);
                                                        }}
                                                        autoFocus={true}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        color="secondary">
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Grid>
                        <Box sx={{overflow: "hidden", padding: "15px"}}>
                            <Loading open={loading}/>

                            {dataSource && (
                                <Box display="flex" flexDirection="column" sx={{overflow: "scroll"}}>
                                    <Box position="sticky" top="0" zIndex={3}>
                                        <Box width="auto" display="flex" flexDirection="row">
                                            <Box position="sticky" left="0" display="flex" flexDirection="column" className="product">
                                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                                    <Typography variant="body2" color="white">{translate.t("product")}</Typography>
                                                </Box>
                                            </Box>
                                            {dataSource.days.map((day, index) => {
                                                return (
                                                    <Box key={index} display="flex" flexDirection="column" className="header-date">
                                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                                            <Typography variant="body2">{formatDate(day)}</Typography>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                    {dataSource.products.map((item, index) => {
                                        return (
                                            <Box key={index}>
                                                <Box display="flex" flexDirection="row">
                                                    <Box position="sticky" zIndex={2} left="0" display="flex" flexDirection="column" className="product">
                                                        <Box style={{backgroundColor: "#f5f5f5", paddingLeft: "0", paddingRight: "8px"}}
                                                            display="flex" flex="1" justifyContent="left" alignItems="center" overflow="hidden">
                                                            <Typography sx={{margin: "15px"}} variant="body2" fontWeight="bold">{item.product}</Typography>
                                                        </Box>
                                                    </Box>
                                                    {item.quantities.map((quantity, index) => {
                                                        return (
                                                            <Box key={index} display="flex" flexDirection="column" className="line-date">
                                                                <Box className={"info"} display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                                                    <Typography variant="body2">{quantity}</Typography>
                                                                </Box>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogShortageComponent;
