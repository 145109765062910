import {Box, Checkbox, Fab, FormControl, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Paper, Typography} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import * as echarts from "echarts/core";
import {GridComponent, TitleComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

import EChartsReact, {useChart} from "echarts-for-react-fc";
import {Field, Form, Formik} from "formik";
import Select from "@mui/material/Select";
import {Search} from "@mui/icons-material";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ProductionLine} from "../../../models/basic/production-line";
import {ErrorComponent} from "../../../components/error/error.component";
import {toast} from "react-toastify";
import "./style.css";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {useKeepFilters} from "../../../utils/form-utils";

echarts.use([GridComponent, BarChart, CanvasRenderer, TooltipComponent, TitleComponent]);


const UsageProductionLines = (props: any) => {
    const {showFilters = true, namespace = ""} = props;
    const service = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();
    const [chartData, setChartData] = useState<any>(undefined);
    const [showChart, setShowChart] = useState(false);
    const [chartHeight, setChartHeight] = useState<number>(550);

    const {searchParams, setFilterParameter} = useKeepFilters(namespace);
    const start_date = dayjs(searchParams.get(`${namespace}`+"initial_date") || dayjs());
    const end_date = dayjs(searchParams.get(`${namespace}`+"final_date") || dayjs());
    const type = searchParams.get(`${namespace}`+"process_type") || "S";
    const groupBy = searchParams.get(`${namespace}`+"result") || "consolidated";


    const labelOption = () => {
        return {
            show: true,
            position: "insideLeft",
            distance: 15,
            align: "left",
            verticalAlign: "middle",
            formatter: "{c}% - {a}",
            fontSize: 16,
            fontWeight: "bold",
            textBorderWidth: 0,
            borderRadius: 4,
            padding: 6,
            color: "#fff"
        };
    };

    const handleStartDateChange = (event: any) => {
        setFilterParameter("initial_date", event.format("YYYY-MM-DD"));
    };

    const handleEndDateChange = (event: any) => {
        setFilterParameter("final_date", event.format("YYYY-MM-DD"));
    };

    useEffect(() => {
        if (chartData) {
            const options: any = getChartOptions();
            setChartOption(options, true);
        }
    }, [chartData]);

    const getChartOptions = () => {
        const series: any = [];
        if (chartData.series.length > 0) {
            chartData.series.forEach((el: any) => {
                series.push(
                    {
                        name: el.name,
                        type: "bar",
                        barGap: 0,
                        label: labelOption(),
                        data: el.data,
                    }
                );
            });
            setChartHeight(
                chartData.yAxis.length === 1 ?
                    550 :
                    Number(chartData.yAxis.length * (chartData.legend.length * 70))
            );
            setShowChart(true);
        } else {
            toast.warning(translate.t("no_records_found"));
            setShowChart(false);
        }

        return {
            title: {text: translate.t("production_line_usage")},
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                }
            },
            legend: {
                data: chartData.legend,
                top: 40,
            },
            toolbox: {
                show: true,
                feature: {
                    restore: {show: true, title: translate.t("refresh")},
                    saveAsImage: {show: true, title: translate.t("save")}
                }
            },
            xAxis: {
                type: "value",
                min: 0,
                max: 100
            },
            yAxis: {
                inverse: true,
                type: "category",
                data: chartData.yAxis
            },
            series: series
        };
    };

    const search = () => {
        chartRef.current;
        service.clearParameters();
        service.addParameter("initial_date", dayjs(start_date).format("YYYY-MM-DD"));
        service.addParameter("final_date", dayjs(end_date).format("YYYY-MM-DD"));
        service.addParameter("process_type", type);
        service.addParameter("result", groupBy);
        service.getFromListRoute("usage")
            .then((response: any) => {
                if (response.data) {
                    setChartData(response.data);
                } else {
                    toast.warning(translate.t("no_records_found"));
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("production_line_usage")}
                </Typography>
            </div>
            {showFilters && (
                <Grid>
                    <Paper variant="outlined">
                        <Box p={2}>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{type: "S", groupBy: "consolidated"}}
                                onSubmit={(values: any, {setSubmitting}) => {
                                    setTimeout(() => {
                                        search();
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    ({values, errors, touched}) => (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item xs={12} sm={6} md={2.4} lg={2.4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel className={"formControl"} htmlFor="type">
                                                            {translate.t("type_process")} *
                                                        </InputLabel>
                                                        <Field
                                                            variant={"outlined"}
                                                            defaultValue="S"
                                                            label={translate.t("type_process")}
                                                            name="type"
                                                            component={Select}
                                                            value={type}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                                setFilterParameter("process_type", event.target.value as string)}
                                                        >
                                                            <MenuItem value="S">{translate.t("SMT")}</MenuItem>
                                                            <MenuItem value="M">{translate.t("Manual")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <DatePicker
                                                        maxDate={end_date}
                                                        onChange={handleStartDateChange}
                                                        slotProps={{
                                                            textField: {
                                                                name: "initial_date",
                                                                required: true,
                                                                fullWidth: true,
                                                                label: translate.t("initial_date"),
                                                                value: start_date,
                                                                error: !start_date || (start_date > end_date),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                                InputProps: {
                                                                    startAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {!start_date ? (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            ) : null}
                                                                            {start_date > end_date ? (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_less_equal_than_final_date")}
                                                                                </div>
                                                                            ) : null}
                                                                        </InputAdornment>
                                                                    ),
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <DatePicker
                                                        minDate={start_date}
                                                        onChange={handleEndDateChange}
                                                        slotProps={{
                                                            textField: {
                                                                name: "end_date",
                                                                required: true,
                                                                fullWidth: true,
                                                                label: translate.t("end_date"),
                                                                value: end_date,
                                                                error: !end_date || (end_date < start_date),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                                InputProps: {
                                                                    startAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {!end_date ? (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            ) : null}
                                                                            {end_date < start_date ? (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_bigger_equal_than_start_date")}
                                                                                </div>
                                                                            ) : null}
                                                                        </InputAdornment>
                                                                    ),
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel className={"formControl"} htmlFor="groupBy">
                                                            {translate.t("result")} *
                                                        </InputLabel>
                                                        <Field
                                                            variant={"outlined"}
                                                            defaultValue="consolidated"
                                                            label={translate.t("result")}
                                                            name="groupBy"
                                                            component={Select}
                                                            value={groupBy}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                                setFilterParameter("result", event.target.value as string)}
                                                        >
                                                            <MenuItem
                                                                value="consolidated">{translate.t("consolidated")}
                                                            </MenuItem>
                                                            <MenuItem value="detailed">
                                                                {translate.t("detailed")}
                                                            </MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={0.6} lg={0.6}>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        disabled={!start_date || !end_date || !type || !groupBy}
                                                        color="secondary">
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            )}
            <Grid marginTop={2}>
                {showChart && (<Paper variant="outlined" sx={{marginTop: "10px"}}>
                    <EChartsReact
                        style={{
                            height: chartHeight,
                            padding: 10
                        }}
                        ref={chartRef}
                        echarts={echarts}
                        onChartReady={handleListenChartReady}
                    />
                </Paper>)}
            </Grid>
        </Box>
    );
};

export default UsageProductionLines;
