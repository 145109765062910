import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import {Demand} from "../../../../models/basic/demand";
import {Product} from "../../../../models/basic/product";
import {Version} from "../../../../models/basic/version";
import {MuiColorInput} from "mui-color-input";
import {DateNowEnUS} from "../../../../components/date/date-timeFormat";
import {ErrorComponent} from "../../../../components/error/error.component";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {BaseService} from "../../../../services/base-service";
import ProductionOrderDemandComponent from "./production-order-demand.component";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {useKeepFilters} from "../../../../utils/form-utils";

const DemandItemComponent = () => {
    const params = useParams();
    const url = "/planning/demand/";
    const navigate = useNavigate();
    const serviceDemand = new BaseService<Demand>(URLS.DEMAND);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const serviceVersion = new BaseService<Version>(URLS.VERSION);
    const [demand, setDemand] = React.useState<Demand>(new Demand());
    const [colorValue, setColorValue] = React.useState<string>("#2962FF");
    const [fontColorValue, setFontColorValue] = React.useState<string>("#ffffff");
    const [referenceDateValue, setReferenceDateValue] = useState(dayjs());
    const [productValue, setProductValue] = React.useState<Product | null>(null);
    const [versionValue, setVersionValue] = React.useState<Version | null>(null);
    const [clearAutocomplete, setClearAutocomplete] = useState<boolean>(false);
    const [resetAutocomplete, setResetAutocomplete] = useState<boolean>(false);
    const [buttonValue, setButtonValue] = useState("");

    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        quantity: Yup.number().required(translate.t("required_field")).positive().integer(),
    });

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        serviceProduct.clearParameters();
        serviceProduct.getAll().then(response => {
            retrieveObject(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const retrieveObject = (products: Product[]) => {
        if (params["action"] != "create") {
            if (products.length > 0) {
                serviceDemand.clearParameters();
                serviceDemand.addParameter("expand", "product,version");
                serviceDemand.getById(parseInt(params["action"] as string)).then((response) => {
                    setDemand(response.data);
                    setColorValue(response.data.color);
                    setFontColorValue(response.data.font_color);
                    setProductValue(response.data.product);
                    setVersionValue(response.data.version);
                    setReferenceDateValue(dayjs(response.data.reference_date));
                });
            }
        } else {
            setReferenceDateValue(dayjs(DateNowEnUS()));
        }
    };

    const handleChangeColor = (newColorValue: string) => {
        setColorValue(newColorValue);
    };

    const fontColor = (color: string) => {
        return <div
            style={{
                backgroundColor: colorValue,
                color: color,
                fontWeight: "bold",
                padding: 7,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 2
            }}
        >
            100
        </div>;
    };

    const resetFields = (resetForm: any) => {
        setDemand(new Demand());
        setProductValue(null);
        setVersionValue(null);
        setColorValue("#2962FF");
        setFontColorValue("#ffffff");
        setReferenceDateValue(dayjs(DateNowEnUS()));
        setClearAutocomplete(!clearAutocomplete);
        setResetAutocomplete(true);
        resetForm();
    };

    const handleSelectProduct = (product: Product) => {
        setProductValue(product);
    };

    const handleSelectVersion = (version: Version) => {
        setVersionValue(version);
    };

    const onSubmit = (values: Demand, {resetForm}: FormikHelpers<Demand>) => {
        setTimeout(async () => {
            setResetAutocomplete(false);
            const demand = new Demand();
            Object.assign(demand, values);
            demand.product = productValue?.url;
            demand.version = versionValue?.url;
            demand.color = colorValue;
            demand.font_color = fontColorValue;
            demand.reference_date = referenceDateValue.format("YYYY-MM-DD");
            if (demand.id) {
                serviceDemand.patch(demand, demand.id).then(() => {
                    toast.success(translate.t("successfully_updated"));
                    if (buttonValue === "save") {
                        resetFields(resetForm);
                        navigate(`${url}create`);
                    } else {
                        navigate(previousUrl);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            } else {
                serviceDemand.save(demand)
                    .then(() => {
                        toast.success(translate.t("successfully_registered"));
                        if (buttonValue === "save") {
                            resetFields(resetForm);
                        } else {
                            navigate(previousUrl);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }, 500);
    };

    return (
        <section>
            <div className="floating-title floating-title-item"
                style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("demand")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={demand}
                            onSubmit={onSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <AutocompletePaginated
                                                    display={["model", "name"]}
                                                    searchField="full_description"
                                                    label="product"
                                                    service={serviceProduct}
                                                    onSelectElement={handleSelectProduct}
                                                    element={productValue}
                                                    required={true}
                                                    clear={clearAutocomplete}
                                                    reset={resetAutocomplete}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    onChange={(event: any) => setReferenceDateValue(event)}
                                                    slotProps={{
                                                        textField: {
                                                            name: "reference_date",
                                                            required: true,
                                                            disabled: true,
                                                            fullWidth: true,
                                                            label: translate.t("reference_date"),
                                                            value: referenceDateValue,
                                                            InputLabelProps: {
                                                                shrink: true
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <AutocompletePaginated
                                                    display="name"
                                                    searchField="name"
                                                    label="version"
                                                    service={serviceVersion}
                                                    onSelectElement={handleSelectVersion}
                                                    element={versionValue}
                                                    required={true}
                                                    clear={clearAutocomplete}
                                                    reset={resetAutocomplete}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="quantity"
                                                    label={translate.t("quantity")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    required={true}
                                                    values={values}
                                                    error={touched.quantity && Boolean(errors.quantity)}
                                                    type="number"
                                                    InputProps={{
                                                        min: 0,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.quantity && Boolean(errors.quantity) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <MuiColorInput
                                                    name="color"
                                                    id="color"
                                                    label={translate.t("color")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    inputProps={{
                                                        maxLength: 7,
                                                    }}
                                                    format="hex"
                                                    isAlphaHidden
                                                    value={colorValue}
                                                    onChange={handleChangeColor}
                                                />
                                            </Grid>
                                            <Grid
                                                item xs={12} sm={12} md={4} lg={4}
                                                sx={{paddingLeft: "15px", paddingTop: "10px"}}
                                                container alignItems="center">
                                                <FormControl component="fieldset">
                                                    <Grid container alignItems="center">
                                                        <FormLabel
                                                            required={true}
                                                            sx={{paddingRight: "10px"}}
                                                            component="legend">
                                                            {translate.t("font_color")}:</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            name="font_color"
                                                            onChange={(event) =>
                                                                setFontColorValue(event.target.value)}>
                                                            <FormControlLabel
                                                                value="#000000"
                                                                control={<Radio/>}
                                                                checked={fontColorValue === "#000000"}
                                                                label={fontColor("#000000")}
                                                            />
                                                            <FormControlLabel
                                                                value="#ffffff"
                                                                control={<Radio/>}
                                                                checked={fontColorValue === "#ffffff"}
                                                                label={fontColor("#ffffff")}
                                                            />
                                                        </RadioGroup>
                                                    </Grid>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !versionValue || !productValue}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !versionValue || !productValue}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
            {
                params["action"] != "create" &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                }}>
                    <ProductionOrderDemandComponent demand={demand}/>
                </Box>
            }

        </section>
    );
};

export default DemandItemComponent;
