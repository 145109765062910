import React, {useEffect} from "react";
import {Field, Form, Formik} from "formik";
import Dialog from "@mui/material/Dialog";
import {translate} from "../../../../translate/translate";
import {DialogActions, DialogTitle, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {CallSplit, Close} from "@mui/icons-material";
import {Demand} from "../../../../models/basic/demand";
import {URLS} from "../../../../services/app-urls";
import {BaseService} from "../../../../services/base-service";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import {MuiColorInput} from "mui-color-input";
import {toast} from "react-toastify";


const DialogMoveBalanceComponent = (props: any) => {
    const {
        open,
        onClose,
        demand
    } = props;
    const service = new BaseService<Demand>(URLS.DEMAND);
    const [demandSelected, setDemandSelected] = React.useState<Demand | null>(null);
    const [colorValue, setColorValue] = React.useState<string>("#2962FF");
    const [quantity, setQuantity] = React.useState<number>(0);

    const schema = Yup.object().shape({
        quantity: Yup.number().required().positive().integer(),
        production_order: Yup.string().required().max(12),
    });

    useEffect(() => {
        setDemandSelected(demand);
    }, []);

    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(false);
    };

    const handleChangeColor = (newColorValue: string) => {
        setColorValue(newColorValue);
    };

    const saveDemand = () => {
        const payload = {
            "from_demand": demand.id,
            "new_quantity": quantity,
            "font_color": "#000000",
            "color": colorValue,
        };
        service.clearParameters();
        service.postFromListRoute(payload, "divide")
            .then(() => {
                toast.success(translate.t("successfully_registered"));
                onClose(true);
            }).catch((error) => {
                const {response} = error;
                if (response?.status === 400) {
                    const {data} = response;
                    const message_error = data.non_field_errors || data.detail || "error_required_field";
                    toast.error(translate.t(message_error));
                    return;
                }
                console.log(error);
            });
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex"
                                alignItems="center">
                                <CallSplit fontSize="large" sx={{paddingRight: "5px"}}/>
                                {translate.t("move_balance")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => onClose(false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogTitle sx={{backgroundColor: "#F5F5F5"}}>
                    <Grid container direction="row" spacing={1} whiteSpace="nowrap">
                        <Grid container item direction="row" xs spacing={1}>
                            <Grid item>
                                <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                    {translate.t("product")}:
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                    {demandSelected?.product.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" xs spacing={1}>
                            <Grid item>
                                <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                    {translate.t("balance")}:
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                    {demandSelected?.balance}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" xs spacing={1}>
                            <Grid item>
                                <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                    {translate.t("reference_date")}:
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                    {demandSelected?.reference_date}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <Formik
                        initialValues={demand}
                        validationSchema={schema}
                        validateOnChange={true}
                        enableReinitialize
                        onSubmit={(values: any, {setSubmitting}) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 500);
                        }}>
                        {({touched}) => (
                            <Form autoComplete="off">
                                <Grid container direction="column" spacing={2}>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="quantity"
                                                label={translate.t("quantity")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                value={quantity}
                                                onChange={(e: any) => setQuantity(e.target.value)}
                                                error={touched.quantity && quantity < 1}
                                                type="number"
                                                InputProps={{
                                                    min: 0,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.quantity && quantity < 1 && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <MuiColorInput
                                                name="color"
                                                id="color"
                                                label={translate.t("color")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                error={!colorValue}
                                                inputProps={{
                                                    maxLength: 7,
                                                }}
                                                format="hex"
                                                isAlphaHidden
                                                value={colorValue}
                                                onChange={handleChangeColor}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {!colorValue && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => onClose(false)}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                    <Button
                        className="button-options"
                        color="success"
                        onClick={() => saveDemand()}
                        disabled={quantity < 1 || !colorValue}
                        variant="contained">
                        {translate.t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogMoveBalanceComponent;
