import {BaseService} from "../../../../services/base-service";
import {Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Station} from "../../../../models/basic/station";
import {ErrorComponent} from "../../../../components/error/error.component";
import {useKeepFilters} from "../../../../utils/form-utils";


const StationItemComponent = () => {
    const [object, setObject] = React.useState(new Station());
    const service = new BaseService<Station>(URLS.STATION);
    const params = useParams();
    const url = "/registration/station/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [type, setType] = React.useState<any>();

    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        name: Yup.string().required()
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setType(response.data.type);
            });
        }
    }, []);

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("station")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: Station, {resetForm}: FormikHelpers<Station>) => {
                                setTimeout(() => {
                                    const station = new Station();
                                    Object.assign(station, values);
                                    station.type = type;
                                    if (station.id) {
                                        try {
                                            service.patch(station, station.id)
                                                .then(() => {
                                                    toast.success(translate.t("successfully_updated"));
                                                    if (buttonValue === "save") {
                                                        setObject(new Station());
                                                        setType("");
                                                        resetForm();
                                                    } else {
                                                        navigate(previousUrl);
                                                    }
                                                })
                                                .catch((error: any) => {
                                                    ErrorComponent(error);
                                                });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        try {
                                            service.save(station)
                                                .then(() => {
                                                    toast.success(translate.t("successfully_registered"));
                                                    if (buttonValue === "save") {
                                                        setObject(new Station());
                                                        resetForm();
                                                        setType("");
                                                    } else {
                                                        navigate(previousUrl);
                                                    }
                                                })
                                                .catch((error) => {
                                                    if (error.response && error.response.status === 400) {
                                                        toast.error(translate.t("error_required_field"));
                                                    }
                                                });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 104,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid sx={{paddingLeft: "15px", paddingTop: "10px"}} container alignItems="center" xs={1}>
                                            <FormControl component="fieldset">
                                                <Grid container alignItems="center">
                                                    <FormLabel
                                                        required={true}
                                                        sx={{paddingRight: "10px"}}
                                                        component="legend">
                                                        {translate.t("type_process")}:</FormLabel>
                                                    <RadioGroup
                                                        row name="type"
                                                        onChange={(event) =>
                                                            setType(event.target.value)}>
                                                        <FormControlLabel
                                                            value="S"
                                                            control={<Radio/>}
                                                            checked={type === "S"}
                                                            label="SMT"/>
                                                        <FormControlLabel
                                                            value="M"
                                                            control={<Radio/>}
                                                            checked={type === "M"}
                                                            label="Manual"/>
                                                    </RadioGroup>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name || !type}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name || !type}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default StationItemComponent;
