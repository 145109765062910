import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Fab, Grid, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {Search, UploadFile} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {Field, Form, Formik} from "formik";
import {ErrorComponent} from "../../../components/error/error.component";
import {SpreadsheetImport} from "../../../models/planning/spreadsheet-import";
import {SpreadsheetImportItem} from "../../../models/planning/spreadsheet-import-item";
import Loading from "../../../components/loading/loading";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../models/basic/product";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import TableActionComponent from "../../../components/table/table-action.component";
import {toast} from "react-toastify";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {useKeepFilters} from "../../../utils/form-utils";

const ImportMaterialTransitComponent = () => {
    const service = new BaseService<SpreadsheetImport>(URLS.SPREADSHEET_IMPORT);
    const serviceSpreadsheetImportItem = new BaseService<SpreadsheetImportItem>(URLS.SPREADSHEET_IMPORT_ITEM);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const [selectedFiles, setSelectedFiles] = React.useState<any>();
    const [dataSource, setDataSource] = React.useState<SpreadsheetImportItem[] | []>([]);
    const [loading, setLoading] = React.useState(false);
    const [importStart, setImportStart] = useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pagination, setPagination] = React.useState<PaginatedResult<SpreadsheetImportItem>>();
    const fileInputRef = React.useRef<any>(null);

    const {searchParams, setFilterParameter} = useKeepFilters();
    const searchProduct = searchParams.get("product") || "";
    const searchStart = searchParams.get("start") ? dayjs(searchParams.get("start")) : null;
    const searchEnd = searchParams.get("end") ? dayjs(searchParams.get("end")) : null;

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleImportStartDateChange = (event: any) => {
        setImportStart(event);
    };

    const handleStartDateChange = (event: any) => {
        setFilterParameter("start", event.format());
    };

    const handleEndDateChange = (event: any) => {
        setFilterParameter("end", event.format());
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const search = (restartIndex = false) => {
        if (searchProduct) serviceSpreadsheetImportItem.addParameter("product", searchProduct);
        if (searchStart) serviceSpreadsheetImportItem.addParameter("start", dayjs(searchStart).format("YYYY-MM-DD"));
        if (searchEnd) serviceSpreadsheetImportItem.addParameter("end", dayjs(searchEnd).format("YYYY-MM-DD"));

        serviceSpreadsheetImportItem.addParameter("expand", "product");
        serviceSpreadsheetImportItem.addParameter("limit", rowsPerPage);
        serviceSpreadsheetImportItem.addParameter("offset", (page * rowsPerPage));

        serviceSpreadsheetImportItem.getAllPaginate()
            .then(response => {
                setPagination(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
        restartIndex && setPage(0);
    };


    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("import_material_transit")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid container spacing={2} direction={"row"}>
                <Grid item xs>
                    <Paper variant="outlined">
                        <Box p={2}>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{}}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                        setLoading(true);
                                        service.clearParameters();
                                        service.addParameter("import_start_date", dayjs(importStart).format("YYYY-MM-DD"));
                                        service.uploadFile("spreadsheet_import", selectedFiles)
                                            .then(() => {
                                                fileInputRef.current.value = null;
                                                setSelectedFiles(null);
                                                search(true);
                                                toast.success(translate.t("successfully_uploaded"));
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            })
                                            .finally(() => setLoading(false));
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    () => (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        className={"input-file"}
                                                        name="file"
                                                        label={""}
                                                        type={"file"}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={true}
                                                        inputProps={{
                                                            accept: ".xls,.xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                        }}
                                                        inputRef={fileInputRef}
                                                        onChange={(event: any) => {
                                                            const files = Array.from(event.target.files);
                                                            setSelectedFiles(files);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DatePicker
                                                        onChange={handleImportStartDateChange}
                                                        slotProps={{
                                                            textField: {
                                                                name: "initial_date",
                                                                label: translate.t("initial_date"),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        color="secondary"
                                                        disabled={!selectedFiles || !importStart}>
                                                        <UploadFile/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sm>
                    <Paper variant="outlined">
                        <Box p={2}>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{}}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                        search(true);
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    () => (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <AutocompletePaginated
                                                        keepFilter={searchProduct}
                                                        display={["model","name"]}
                                                        searchField="full_description"
                                                        label="product"
                                                        service={productService}
                                                        onSelectElement={(product: Product) => {
                                                            setFilterParameter("product", product);
                                                        }}
                                                        autoFocus={true}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DatePicker
                                                        maxDate={searchEnd}
                                                        onChange={handleStartDateChange}
                                                        value={searchStart}
                                                        slotProps={{
                                                            textField: {
                                                                name: "initial_date",
                                                                label: translate.t("initial_date"),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DatePicker
                                                        minDate={searchStart}
                                                        value={searchEnd}
                                                        onChange={handleEndDateChange}
                                                        slotProps={{
                                                            textField: {
                                                                name: "end_date",
                                                                label: translate.t("end_date"),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        color="secondary">
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "product", "delivery_date", "quantity"]}
                    displayedColumns={["id", "product.model_and_name", "delivery_date", "quantity"]}
                    dateColumns={["delivery_date"]}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    columnStyles={{1: "5%", 2: "20%", 3: "10%", 4: "10%"}}
                />
            </Paper>
        </section>
    );
};

export default ImportMaterialTransitComponent;
