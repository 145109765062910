import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {translate} from "../../../../translate/translate";
import {Grid, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {BaseService} from "../../../../services/base-service";
import {PlanningCalendar} from "../../../../dto/planning-calendar";
import {URLS} from "../../../../services/app-urls";
import {Marker} from "../../../../models/basic/marker";

const DialogMarkers = (props: any) => {
    const {
        open,
        onClose,
        dailyPlanning,
    } = props;
    const service = new BaseService<PlanningCalendar>(URLS.DAILY_PLANNING);
    const [markers, setMarkers] = useState<Marker[]>([]);

    const getMarkers = () => {
        service.clearParameters();
        service.addParameter("expand", "marker");
        service.getById(dailyPlanning.daily_planning_id).then((response) => {
            setMarkers(response.data.marker);
        });
    };

    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose();
    };

    useEffect(() => {
        getMarkers();
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t("markers")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={onClose}/>
                </Grid>
            </Grid>
            <DialogContent>

                <Grid display="flex" flexDirection="row" alignItems="start" container spacing={1} sx={{height: "150px", marginTop: "20px"}}>
                    <Grid item container direction={"row"}>
                        {markers.map((marker: any) => {
                            return (
                                <Grid key={marker.id} alignItems="start" container spacing={1} direction="row">
                                    <Grid item>
                                        <Typography sx={{
                                            position: "relative",
                                            marginLeft: "16px",
                                            marginRight: "8px",
                                            display: "inlineblock",
                                            width: "15px",
                                            height: "15px",
                                            borderRadius: "3px",
                                            top: "3px",
                                            backgroundColor: marker.color
                                        }}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{marginLeft: "5px"}}>
                                            {marker.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogMarkers;
