import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../translate/translate";
import {
    Box,
    Checkbox,
    DialogActions,
    DialogTitle,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {PlanningCalendar} from "../../../../dto/planning-calendar";
import {Close} from "@mui/icons-material";
import * as Yup from "yup";
import {DateNowEnUS} from "../../../../components/date/date-timeFormat";
import {PlanningDemand} from "../../../../dto/planning-demand";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {ProductionLine} from "../../../../models/basic/production-line";
import {Shift} from "../../../../models/basic/shift";
import {ProductionRamp} from "../../../../models/basic/production-ramp";
import {ScheduledStop} from "../../../../models/basic/scheduled-stop";
import {toast} from "react-toastify";
import {ManualDefaultTime} from "../../../../models/basic/manual-default-time";
import {WorkerPlanning} from "../../../../models/basic/worker-planning";
import {ErrorComponent} from "../../../../components/error/error.component";
import {Demand} from "../../../../models/basic/demand";
import {DailyPlanning} from "../../../../models/basic/daily-planning";
import {LocalizationProvider, TimeField} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const DialogPlanningDemandComponent = (props: any) => {
    const {
        open,
        onClose,
        demand,
        dailyPlanning
    } = props;
    const serviceWorkerPlanning = new BaseService<WorkerPlanning>(URLS.PLANNING);
    const serviceDailyPlanning = new BaseService<DailyPlanning>(URLS.DAILY_PLANNING);
    const productionRampService = new BaseService<ProductionRamp>(URLS.PRODUCTION_RAMP);
    const scheduledStopService = new BaseService<ScheduledStop>(URLS.SCHEDULED_STOP);
    const manualDefaultTimeService = new BaseService<ManualDefaultTime>(URLS.MANUAL_DEFAULT_TIME);
    const service = new BaseService<PlanningCalendar>(URLS.PLANNING);
    const productionLineService = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const shiftService = new BaseService<Shift>(URLS.SHIFT);
    const [planningDemand, setPlanningDemand] = React.useState(new PlanningDemand());
    const [differentLine, setDifferentLine] = React.useState(false);
    const [productionLineTopValue, setProductionLineTopValue] = React.useState<ProductionLine | null>(null);
    const [productionLineBotValue, setProductionLineBotValue] = React.useState<ProductionLine | null>(null);
    const [productionLineBackendValue, setProductionLineBackendValue] = React.useState<ProductionLine | null>(null);
    const [workerPlanning, setWorkerPlanning] = React.useState(new WorkerPlanning());
    const [productionRampValue, setProductionRampValue] = React.useState<ProductionRamp | null>(null);
    const [scheduledStopValue, setScheduledStopValue] = React.useState<ScheduledStop | null>(null);
    const [selectedShifts, setSelectedShifts] = React.useState<Shift[]>([]);
    const [shifts, setShifts] = React.useState<any[]>([]);
    const [allShifts, setAllShifts] = React.useState<Shift[]>([]);
    const [shiftToStartValue, setShiftToStartValue] = React.useState<string | undefined>("");
    const [showShiftToStart, setShowShiftToStart] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [demandValue, setDemandValue] = React.useState<Demand | null>(null);
    const [shiftsManual, setShiftsManual] = React.useState<any[]>([]);
    const [isShiftToStartValid, setIsShiftToStartValid] = useState(false);
    const [isItemSelected, setIsItemSelected] = useState(false);
    const [duration, setDuration] = useState<any>();
    const [urgentPlanning, setUrgentPlanning] = useState(false);
    const [clearAutocomplete, setClearAutocomplete] = useState<boolean>(false);

    const initializePlanning = () => {
        const now = new Date();
        now.setDate(now.getDate() + 1);
        const planning = new PlanningDemand();
        planning.quantity = dailyPlanning?.worker_planning_quantity || demand?.quantity;
        if (workerPlanning) {
            if (workerPlanning.first_start) {
                planning.initial_date = dayjs(workerPlanning.first_start).format();
            } else if (workerPlanning.id) {
                planning.initial_date = dayjs(workerPlanning.start);
            } else {
                planning.initial_date = dayjs(DateNowEnUS(now));
            }
        } else {
            planning.initial_date = dayjs(DateNowEnUS(now));
        }
        setPlanningDemand(planning);
    };

    useEffect(() => {
        getShifts();
        if (dailyPlanning) {
            setIsEditMode(true);
            getWorkerPlanning();
        } else {
            initializePlanning();
        }
    }, []);

    useEffect(() => {
        initializePlanning();
    }, [workerPlanning]);

    useEffect(() => {
        productionLineBotValue ? setDifferentLine(true) : null;
    }, [productionLineBotValue]);

    useEffect(() => {
        if (urgentPlanning) {
            formik.setFieldValue("initial_date", dayjs());
        }
    }, [urgentPlanning]);

    const getWorkerPlanning = () => {
        serviceWorkerPlanning.clearParameters();
        serviceWorkerPlanning.addParameter("group_planning", dailyPlanning.group_planning_id);
        serviceWorkerPlanning.addParameter("expand", "production_line,group_planning.demand.version,group_planning.production_ramp");
        serviceWorkerPlanning.getAll().then(response => {
            planningDemand.initial_date = dayjs(workerPlanning.start).format();
            setWorkerPlanning(response?.data[0]);
            setProductionRampValue(response?.data[0].group_planning.production_ramp);
            setDemandValue(response?.data[0].group_planning.demand);
            response?.data[0].duration ?
                (() => {
                    const formattedDuration = dayjs(response.data[0].duration, "HH:mm:ss");
                    setDuration(formattedDuration);
                })() : null;
            const productionLines = response?.data;
            const topProductionLine = productionLines.find(line => line.type_production_line === "T");
            const botProductionLine = productionLines.find(line => line.type_production_line === "B");
            const backendProductionLine = productionLines.find(line => line.type_production_line === "M");
            topProductionLine ? setProductionLineTopValue(topProductionLine.production_line) : null;
            botProductionLine ? setProductionLineBotValue(botProductionLine.production_line) : null;
            backendProductionLine ? setProductionLineBackendValue(backendProductionLine.production_line) : null;
            const ids: number[] = [];
            response?.data.forEach(item => {
                ids.push(item.id);
            });
            const workerPlanningIds = ids.join(",");
            getDailyPlanning(workerPlanningIds);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getDailyPlanning = (value: any) => {
        serviceDailyPlanning.addParameter("ordering", "id");
        serviceDailyPlanning.addParameter("worker_planning_list", value);
        serviceDailyPlanning.addParameter("expand", "manual_default_time,line_setup,shift");
        serviceDailyPlanning.getAll().then(response => {
            setScheduledStopValue(response?.data[0]?.line_setup);
            const shiftManualDefaultTimeList: any = [];
            const seenIDs: any = {};
            for (let i = 0; i < response.data.length; i++) {
                const item = response.data[i];
                if (item.manual_default_time && item.shift) {
                    const manualDefaultTimeID = item.manual_default_time.id;
                    const shiftID = item.shift.id;
                    const uniqueKey = `${manualDefaultTimeID}-${shiftID}`;
                    if (!seenIDs[uniqueKey]) {
                        shiftManualDefaultTimeList.push({
                            manual_default_time: item.manual_default_time,
                            shift: item.shift
                        });
                        seenIDs[uniqueKey] = true;
                    }
                }
            }
            mergeShiftsAndManualTimes(shiftManualDefaultTimeList);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const validatePlanned = () => {
        if (demand) {
            return demand.planned_quantity > 0 ? demand.quantity - demand.planned_quantity : demand.quantity;
        }
    };

    const handleSelectShiftToStart = (shift: Shift) => {
        setShiftToStartValue(shift?.url);
        setIsShiftToStartValid(!!shift);
    };

    const handleSelectProductionLineTop = (event: any) => {
        setProductionLineTopValue(event);
    };

    const handleSelectProductionLineBot = (event: any) => {
        setProductionLineBotValue(event);
    };

    const handleSelectProductionLineBackend = (event: any) => {
        setProductionLineBackendValue(event);
    };

    const getShifts = () => {
        shiftService.clearParameters();
        shiftService.addParameter("ordering", "order");
        shiftService.getAll()
            .then((response: any) => {
                setAllShifts(response.data);
            });
    };

    const mergeShiftsAndManualTimes = (shiftManualDefaultTimeList: []) => {
        if (shiftManualDefaultTimeList.length) {
            const newStateShifts: any[] = [];
            shiftManualDefaultTimeList.forEach((item: any) => {
                const shiftAlreadyExists = shifts.some((shiftItem) => shiftItem.shift === item.shift.url);
                if (!shiftAlreadyExists) {
                    newStateShifts.push(item.shift);
                    setSelectedShifts(newStateShifts);
                    setShowShiftToStart(newStateShifts.length > 1);
                    shiftsManual.push({
                        "shift": item.shift,
                        "selected": true,
                        "manual_default_time": item.manual_default_time
                    });
                    setIsItemSelected(true);
                    setShiftsManual(shiftsManual);
                    handleCapacity(item.manual_default_time, item.shift);
                }
            });
            setIsItemSelected(true);
        }
    };

    const handleChangeShifts = (event: any, shift: any) => {
        let newStateShifts = [];
        const isChecked = event.target.checked;
        if (isChecked) {
            newStateShifts.push(...selectedShifts);
            newStateShifts.push(shift);
            if (!productionLineBackendValue) {
                shifts.push({
                    "shift": shift.url,
                    "selected": true,
                    "manual_default_time": undefined
                });
                setShifts(shifts);
                setIsItemSelected(true);
            }
            if (newStateShifts.length == 2) {
                setClearAutocomplete(!clearAutocomplete);
                setIsShiftToStartValid(false);
                setShiftToStartValue(undefined);
            }
        } else {
            handleCapacity(new ManualDefaultTime(), shift);
            newStateShifts = selectedShifts.filter(s => s.id !== shift.id);
            setShifts(shifts.filter(s => s.shift !== shift.url));
            if (shiftToStartValue == shift.url) {
                setClearAutocomplete(!clearAutocomplete);
                setIsShiftToStartValid(false);
                setShiftToStartValue(undefined);
            }
        }
        setSelectedShifts(newStateShifts);
        setShiftsManual(shiftsManual.filter(s => s.shift.id !== shift.id));
        setShowShiftToStart(newStateShifts.length > 1);
    };

    const handleCapacity = (manualTime: ManualDefaultTime, shift: Shift) => {
        if (manualTime?.id) {
            shifts.push({
                "shift": shift.url,
                "selected": true,
                "manual_default_time": manualTime.url
            });
            setShifts(shifts);
            setIsItemSelected(true);
        } else {
            const updatedShiftsManual = [...shiftsManual];
            const shiftIndex = updatedShiftsManual.findIndex(
                (s) => s.shift.id === shift.id
            );
            if (shiftIndex !== -1) {
                updatedShiftsManual[shiftIndex].manual_default_time = null;
            }
            setShiftsManual(updatedShiftsManual);
            setShifts(shifts.filter((obj) => obj.shift !== shift.url));
            setIsItemSelected(false);
        }
    };

    const handleSelectProductionRamp = (event: any) => {
        setProductionRampValue(event);
    };

    const handleScheduleStop = (event: any) => {
        setScheduledStopValue(event);
    };

    const demandTotal = ():number => {
        return demandValue ? demandValue.planned_quantity  : validatePlanned();
    };

    const formik = useFormik({
        initialValues: planningDemand,
        validationSchema: Yup.object().shape({
            urgent_planning: Yup.boolean(),
            quantity: Yup.number()
                .required()
                .positive()
                .integer().max(demandTotal()),
            initial_quantity: Yup.number()
                .positive()
                .integer()
                .max(demandTotal())
                .test("is-required", translate.t("required_field"), function(value) {
                    return urgentPlanning ? !!value : true;
                }),
        }),
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: (values: any, {setSubmitting}) => {
            setTimeout(() => {
                setSubmitting(false);
            }, 500);
        }
    });

    const handleClose = (event: any, reason: any, result: boolean) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(result);
    };

    const startPlanning = (values: any) => {
        const production_lines = [];
        if (productionLineTopValue) {
            production_lines.push({
                "production_line": productionLineTopValue?.url,
                "type": "T"
            });
        }
        if (productionLineBotValue) {
            production_lines.push({
                "production_line": productionLineBotValue?.url,
                "type": "B"
            });
        }
        if (productionLineBackendValue) {
            production_lines.push({
                "production_line": productionLineBackendValue?.url,
                "type": "M"
            });
        }
        if (production_lines.length === 0) {
            toast.error(translate.t("production_line_required"));
            return;
        }
        const payload = {
            "shifts": shifts,
            "initial_date": dayjs(values.initial_date).format("YYYY-MM-DD"),
            "demand": demand ? demand.url : demandValue?.url,
            "version": demand ? demand.version.url : demandValue?.version.url,
            "production_ramp": productionRampValue?.url,
            "quantity": values.quantity,
            "shift_to_start": shifts.length === 1 ? shifts[0].shift : shiftToStartValue,
            "push": true,
            "production_lines": production_lines,
            "line_setup": scheduledStopValue?.url,
            "is_intercalate": true,
            "group_planning_intercalate_id": null,
            "is_edit": isEditMode,
            "worker_planning": workerPlanning.id,
            "group_planning": workerPlanning ? workerPlanning.group_planning.id : null,
            "duration": duration ? duration.format("HH:mm:ss") : null,
            "urgent_planning": urgentPlanning,
            "initial_quantity": values.initial_quantity
        };
        service.clearParameters();
        service.postFromListRoute(payload, "start_planning")
            .then(() => {
                isEditMode ?
                    toast.success(translate.t("successfully_updated")) :
                    toast.success(translate.t("successfully_registered"));
                handleClose(null, null, true);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const buttonDisabled = () => {
        if (shifts.length === selectedShifts.length && shifts.length < 2) {
            return (
                !formik.isValid ||
                selectedShifts.length === 0 ||
                !productionRampValue
            );
        } else {
            return (
                !formik.isValid ||
                selectedShifts.length === 0 ||
                !productionRampValue ||
                (showShiftToStart && !isShiftToStartValid) ||
                (urgentPlanning && (!formik.values.initial_quantity || formik.values.initial_quantity > formik.values.quantity || formik.values.initial_quantity < 1))
            );
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={(event, reason) => handleClose(event, reason, false)} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle sx={{backgroundColor: "primary.main"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                                {isEditMode ? translate.t("edit_planning") : translate.t("planning")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => handleClose(null, null, false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogTitle sx={{backgroundColor: "#F5F5F5"}}>
                    <Grid container direction="column" spacing={1}>
                        <Grid container item direction="row">
                            <Grid container item direction="row" xs spacing={1}>
                                <Grid item>
                                    <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                        {translate.t("model")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                        {demand ? demand?.product?.model : dailyPlanning?.model}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" xs spacing={1}>
                                <Grid item>
                                    <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                        {translate.t("product")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                        {demand ? demand?.product?.name : dailyPlanning?.product_description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row">
                            <Grid container item direction="row" xs spacing={1}>
                                <Grid item>
                                    <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                        {translate.t("demand_total")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                        {demandTotal()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off">
                            <Grid container paddingTop={2} direction="column" spacing={2}>
                                <Grid container item direction="row" spacing={2} alignItems={"center"}>
                                    <Grid item xs>
                                        <Field
                                            as={TextField}
                                            name="quantity"
                                            label={translate.t("quantity")}
                                            variant={"outlined"}
                                            fullWidth
                                            required={true}
                                            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                            values={formik.values.quantity}
                                            disabled={
                                                new Date(dailyPlanning?.worker_planning_start) <
                                                new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                            }
                                            onChange={(event: any) => formik.handleChange(event)}
                                            onInput={(e: any) => e.target.value = e.target.value.replace(/[^0-9]/g, "")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {
                                                            formik.touched.quantity && Boolean(formik.errors.quantity) && (
                                                                (formik.values.quantity > demandTotal()) ?
                                                                    (<div className={"required-field"}>{translate.t("max_qtd")}{demandTotal()}</div>) :
                                                                    (<div className={"required-field"}>{translate.t("required_field")}</div>)
                                                            )
                                                        }
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{ maxLength: 10 }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <DatePicker
                                            minDate={dayjs()}
                                            onChange={(event) => formik.setFieldValue("initial_date", event)}
                                            disabled={
                                                urgentPlanning ||
                                                new Date(dailyPlanning?.worker_planning_start) <
                                                new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                            }
                                            slotProps={{
                                                textField: {
                                                    name: "initial_date",
                                                    fullWidth: true,
                                                    value: formik.values.initial_date,
                                                    label: translate.t("initial_date"),
                                                    InputLabelProps: {
                                                        shrink: true
                                                    },
                                                    error: formik.touched.reference_date && Boolean(formik.errors.reference_date)
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Box display="flex" alignItems="center">
                                            <Field
                                                disabled={false}
                                                as={Checkbox}
                                                name="urgent_planning"
                                                checked={urgentPlanning}
                                                onChange={() => setUrgentPlanning(!urgentPlanning)}
                                            />
                                            <label>{translate.t("urgent_planning")}</label>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {urgentPlanning && (
                                    <Grid item xs>
                                        <Field
                                            as={TextField}
                                            name="initial_quantity"
                                            label={translate.t("initial_quantity")}
                                            variant={"outlined"}
                                            fullWidth
                                            required={urgentPlanning}
                                            error={formik.touched.initial_quantity && Boolean(formik.errors.initial_quantity)}
                                            values={formik.values.initial_quantity}
                                            disabled={!urgentPlanning}
                                            onChange={(event: any) => formik.handleChange(event)}
                                            onInput={(e: any) => e.target.value = e.target.value.replace(/[^0-9]/g, "")}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {
                                                            formik.touched.initial_quantity && Boolean(formik.errors.initial_quantity) && (
                                                                (formik.values.initial_quantity > formik.values.quantity) ?
                                                                    (<div className={"required-field"}>{translate.t("max_qtd")}{formik.values.quantity}</div>) :
                                                                    (<div className={"required-field"}>{translate.t("required_field")}</div>)
                                                            )
                                                        }
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{ maxLength: 10 }}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs>
                                    <Field
                                        disabled={
                                            new Date(dailyPlanning?.worker_planning_start) <
                                            new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                        }
                                        as={Checkbox}
                                        name="different_line"
                                        checked={differentLine}
                                        onChange={() => setDifferentLine(!differentLine)}
                                    />
                                    <label>{translate.t("different_line")}</label>
                                    <AutocompletePaginated
                                        display="name"
                                        searchField="name"
                                        label="production_line_smt"
                                        service={productionLineService}
                                        filters={[{"active": true,
                                            "type": "S",
                                            "product": demand ? demand.product.id : dailyPlanning?.id_product
                                        }]}
                                        onSelectElement={handleSelectProductionLineTop}
                                        required={differentLine}
                                        element={productionLineTopValue}
                                        disabled={
                                            new Date(dailyPlanning?.worker_planning_start) <
                                            new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                        }
                                    />
                                    <Grid sx={{marginTop: "10px"}}>
                                        {(differentLine && productionLineTopValue) && (<AutocompletePaginated
                                            display="name"
                                            searchField="name"
                                            label="production_line_bot"
                                            service={productionLineService}
                                            element={productionLineBotValue}
                                            filters={[{
                                                "active": true,
                                                "type": "S",
                                                "not_in": productionLineTopValue?.id,
                                                "product": demand ? demand.product.id : dailyPlanning?.id_product
                                            }]}
                                            onSelectElement={handleSelectProductionLineBot}
                                            required={differentLine}
                                        />)}
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <AutocompletePaginated
                                        display="name"
                                        searchField="name"
                                        label="production_line_manual"
                                        service={productionLineService}
                                        filters={[{
                                            "active": true,
                                            "type": "M",
                                            "product": demand ? demand.product.id : dailyPlanning?.id_product
                                        }]}
                                        element={productionLineBackendValue}
                                        onSelectElement={handleSelectProductionLineBackend}
                                        disabled={
                                            new Date(dailyPlanning?.worker_planning_start) <
                                            new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                        }
                                    />
                                </Grid>
                                {(productionLineBackendValue) && (
                                    <>
                                        <Grid item xs>
                                            <label>{translate.t("add_delay")}</label>
                                        </Grid>
                                        <Grid item xs>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimeField
                                                    name="duration"
                                                    autoFocus={false}
                                                    fullWidth
                                                    format="HH:mm:ss"
                                                    label={translate.t("duration")}
                                                    value={duration}
                                                    onChange={(value: any) => setDuration(value)}
                                                    InputLabelProps={{tabIndex: 1, variant: "outlined"}}
                                                    required={false}
                                                    disabled={
                                                        new Date(dailyPlanning?.worker_planning_start) <
                                                        new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs>
                                    {allShifts.length > 0 ? (
                                        allShifts.map((shift: Shift) => (
                                            <Grid key={shift.id}>
                                                <Checkbox
                                                    disabled={
                                                        new Date(dailyPlanning?.worker_planning_start) <
                                                        new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                                    }
                                                    checked={selectedShifts.find(obj => (obj.id === shift.id)) !== undefined}
                                                    onChange={(p: any) => handleChangeShifts(p, shift)}
                                                />
                                                <label>
                                                    <strong>{translate.t("shift") + ": "}</strong>
                                                    {shift.name}
                                                </label>
                                                {selectedShifts.find(obj => (obj.id === shift.id)) !== undefined && productionLineBackendValue && (
                                                    <AutocompletePaginated
                                                        display="capacity"
                                                        searchField="capacity"
                                                        label="capacity"
                                                        service={manualDefaultTimeService}
                                                        filters={[{
                                                            "active": true,
                                                            "product": demand ? demand.product.id : dailyPlanning?.id_product,
                                                            "lines": [productionLineBackendValue?.id]
                                                        }]}
                                                        element={shiftsManual.find(obj => obj.shift.id === shift.id)?.manual_default_time}
                                                        onSelectElement={(value: any) => {
                                                            handleCapacity(value, shift);
                                                        }}
                                                        required={selectedShifts.find(obj => (obj.id === shift.id)) !== undefined && productionLineBackendValue}
                                                    />
                                                )}
                                            </Grid>
                                        ))
                                    ) : (
                                        <MenuItem disabled>{translate.t("no_shift")}</MenuItem>
                                    )}
                                    <Grid sx={{marginTop: "15px"}}>
                                        {selectedShifts?.length > 1 && (
                                            <AutocompletePaginated
                                                display="name"
                                                searchField="name"
                                                label="shift_to_start"
                                                service={shiftService}
                                                filters={[{
                                                    "active": true,
                                                    "id_in": selectedShifts.map((obj) => obj.id)
                                                }]}
                                                required={selectedShifts.length}
                                                onSelectElement={handleSelectShiftToStart}
                                                clear={clearAutocomplete}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <AutocompletePaginated
                                        display="name"
                                        searchField="name"
                                        label="production_ramp"
                                        service={productionRampService}
                                        filters={[{"active": true}]}
                                        onSelectElement={handleSelectProductionRamp}
                                        required={true}
                                        element={productionRampValue}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <AutocompletePaginated
                                        display="name"
                                        searchField="name"
                                        label="line_setup_stop"
                                        service={scheduledStopService}
                                        filters={[{"active": true, "type": "L"}]}
                                        onSelectElement={handleScheduleStop}
                                        required={false}
                                        element={scheduledStopValue}
                                        disabled={
                                            new Date(dailyPlanning?.worker_planning_start) <
                                            new Date() || (dailyPlanning?.worker_planning_first_start && new Date(dailyPlanning?.worker_planning_first_start) < new Date())
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => handleClose(null, null, false)}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                    <Button
                        className="button-options"
                        color="success"
                        onClick={() => startPlanning(formik.values)}
                        disabled={buttonDisabled()}
                        variant="contained">
                        {isEditMode ? translate.t("save") : translate.t("to_plan")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogPlanningDemandComponent;
