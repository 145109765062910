import "./dashboard-planning.component.css";
import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {translate} from "../../translate/translate";
import {AccountCircle, DisplaySettings, SnoozeOutlined} from "@mui/icons-material";
import {Tooltip} from "react-tooltip";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import {PlanningCalendar, PlanningMonthKey} from "../../dto/planning-calendar";
import {Month} from "../../dto/months";
import {WebSocketService} from "../../services/websocket-service";
import {BaseService} from "../../services/base-service";
import {Version} from "../../models/basic/version";
import {URLS} from "../../services/app-urls";
import {ErrorComponent} from "../../components/error/error.component";
import bgHeader from "../../../assets/images/bg-header.svg";
import Toolbar from "@mui/material/Toolbar";
import {Link} from "react-router-dom";
import logo from "../../../assets/images/logo.svg";

dayjs.locale("pt-br");

const DashboardPlanningComponent = () => {
    const serviceVersion = new BaseService<Version>(URLS.VERSION);
    const [planning, setPlanning] = useState<PlanningCalendar>();
    const [monthKeys, setMonthKeys] = useState<PlanningMonthKey[]>([]);
    const [start_date] = useState(dayjs());
    const month_calendar = Month.loadObject();
    const [lastUpdated, setLastUpdated] = useState(dayjs());
    const [nextUpdate, setNextUpdate] = useState(dayjs().add(10, "minute"));
    const [currentIndex, setCurrentIndex] = useState(0);
    const LINES_PER_PAGE = 4;
    const SWITCH_INTERVAL = 15000;

    const getStringMonth = (index: string) => {
        return Object.values(month_calendar)[Number(index) - 1] as string;
    };

    useEffect(() => {
        updateTimestamps();
        const intervalId = setInterval(updateTimestamps, 10 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(scrollLines, SWITCH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [planning]);

    useEffect(() => {
        updatePlanning();
    }, []);

    useEffect(() => {
        getPlanning();
    }, []);

    const updateTimestamps = () => {
        const now = dayjs();
        const next = now.add(10, "minute");
        setLastUpdated(now);
        setNextUpdate(next);
    };

    const scrollLines = () => {
        if (planning?.body?.lines) {
            setCurrentIndex((prevIndex) => {
                const nextIndex = prevIndex + LINES_PER_PAGE;
                return nextIndex >= planning.body.lines.length ? 0 : nextIndex;
            });
        }
    };

    const processResponse = (data: any) => {
        const keys = [];
        keys.push({
            "year": "0",
            "month": "0",
            "yearMonth": "Lines"
        });

        Object.keys(data.header).forEach((yearMonth: string) => {
            const yearMonthSplit = yearMonth.split("-");
            keys.push({
                "year": yearMonthSplit[0],
                "month": yearMonthSplit[1],
                "yearMonth": yearMonth
            });
        });

        setMonthKeys(keys);
        setPlanning(data);
    };

    const getPlanning = () => {
        serviceVersion.clearParameters();
        serviceVersion.getFromListRoute("get_final_version")
            .then((response: any) => {
                processResponse(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const updatePlanning = () => {
        const webSocketService = new WebSocketService("dashboard_planning");
        webSocketService.onMessage((response: any) => {
            let result = response.data;
            result = JSON.parse(result);

            if (typeof result === "string") {
                result = JSON.parse(result);
            }
            processResponse(result);
        });

        return () => {
            webSocketService.close();
        };
    };

    const handleScroll = (event: any) => {
        const newScrollPosition = event.target.scrollLeft;
        const boxHeader = document.getElementById("boxHeader");
        if (boxHeader) {
            boxHeader.scrollLeft = newScrollPosition;
        }
    };

    const capitalizeFirstLetter = (string: string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const isWeekend = (date: any) => {
        const dayOfWeek = dayjs(date).day();
        return dayOfWeek === 0 || dayOfWeek === 6;
    };

    const displayedLines = planning?.body?.lines.slice(currentIndex, currentIndex + LINES_PER_PAGE) || [];

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <Toolbar
                style={{
                    backgroundImage: `url(${bgHeader})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundColor: "#2537A8",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0
                }}>
                <Box sx={{display: "flex"}}>
                    <Link to="/home" className="logo">
                        <Box
                            component="img"
                            sx={{
                                height: 32,
                            }}
                            alt="Logo P-Plan"
                            src={logo}/>
                    </Link>
                </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
                    <p className="text">{translate.t("last_updated")}: {lastUpdated.format("HH:mm:ss")}</p>
                    <p className="text">{translate.t("next_updated")}: {nextUpdate.format("HH:mm:ss")}</p>
                </Box>
            </Toolbar>
            <Box component="main" className="dashboard-planning" sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 64px)",
                marginTop: "64px"
            }}>
                <Box className="main-content-planning">
                    <Box display="flex" className="box-months" id="boxHeader" onScroll={handleScroll}>
                        {monthKeys.map((month, index) => {
                            return (
                                <Box key={index}>
                                    <Box display="flex" justifyContent="start" alignItems="center" className="month">
                                        {month.month != "0" && (<Typography
                                            variant="body2">{translate.t(getStringMonth(month.month))}</Typography>)}
                                    </Box>
                                    <Box width="auto" display="flex" flexDirection="row" className="header-planning">
                                        {month.month === "0" ?
                                            (<>
                                                <Box className="phantom-box"></Box>
                                                <Box className="phantom-box"></Box>
                                            </>)
                                            :
                                            (<Box display="flex" flexDirection="row">
                                                {Object.keys(planning?.header).map((monthKey) => {
                                                    const monthData = planning?.header[monthKey];
                                                    return (
                                                        Object.keys(monthData).map((itemKey, index) => {
                                                            const item = monthData[itemKey];
                                                            return (
                                                                parseInt(month.month) == item.month &&
                                                                (<Box key={index} display="flex" flexDirection="column" className="header-day">
                                                                    {isWeekend(dayjs(start_date).add(index, "day")) ?
                                                                        (<Box
                                                                            display="flex" flex="1" justifyContent="center"
                                                                            alignItems="center" overflow="hidden"
                                                                            className="weekend-day">
                                                                            <Typography variant="body2">
                                                                                {
                                                                                    capitalizeFirstLetter(dayjs(start_date).add(index, "day").format("dddd"))
                                                                                }
                                                                            </Typography>
                                                                        </Box>)
                                                                        :
                                                                        (<Box
                                                                            display="flex" flex="1" justifyContent="center"
                                                                            alignItems="center" overflow="hidden"
                                                                            className="week-day">
                                                                            <Typography variant="body2">
                                                                                {
                                                                                    capitalizeFirstLetter(dayjs(start_date).add(index, "day").format("dddd"))
                                                                                }
                                                                            </Typography>
                                                                        </Box>)}
                                                                </Box>)
                                                            );
                                                        })
                                                    );
                                                })}
                                            </Box>)
                                        }
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    {planning?.body?.lines && (
                        <Box id="lines" onScroll={handleScroll} className="body-planning">
                            {displayedLines.map((line: any, index: number) => {
                                return (
                                    <Box key={index} width="auto" display="flex" position="relative" flexDirection="row">
                                        {line.type === "S" ?
                                            (<Box className="sticky-bg" display="flex">
                                                <Box
                                                    className="smt-line-name"
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <DisplaySettings sx={{fontSize: "1.7vw", marginBottom: "16px"}}></DisplaySettings>
                                                    <Typography variant="body2">{line.name}</Typography>
                                                </Box>
                                            </Box>)
                                            :
                                            (<Box className="sticky-bg" display="flex">
                                                <Box
                                                    className="manual-line-name"
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <AccountCircle sx={{fontSize: "1.7vw", marginBottom: "16px"}}></AccountCircle>
                                                    <Typography variant="body2">{line.name}</Typography>
                                                </Box>
                                            </Box>)
                                        }
                                        <Box position="relative" display="flex" flexDirection="column" className="shifts">
                                            {line.shifts.map((shift: any, index: number) => {
                                                return (
                                                    <Box key={index} position="relative" display="flex" flexDirection="row">
                                                        {line.type === "S" ?
                                                            (<Box width={"auto"} position={"sticky"} zIndex={2} left="115px" sx={{backgroundColor: "#FFFFFF"}}>
                                                                <Box className="smt-shift-name" display="flex" justifyContent="center" alignItems="center" sx={{height: "100% !important"}}>
                                                                    <Typography variant="body2">{shift.name}</Typography>
                                                                </Box>
                                                            </Box>)
                                                            :
                                                            (<Box width={"auto"} position={"sticky"} zIndex={2} left="115px" sx={{backgroundColor: "#FFFFFF"}}>
                                                                <Box className="manual-shift-name" display="flex" justifyContent="center" alignItems="center">
                                                                    <Typography variant="body2">{shift.name}</Typography>
                                                                </Box>
                                                            </Box>)
                                                        }
                                                        {shift.days.map((day: any, index: number) => {
                                                            return (
                                                                <Box
                                                                    id={`${line.id}${shift.id}${day}`}
                                                                    key={index} width="100%"
                                                                    position="relative" display="flex"
                                                                    flexDirection="row"
                                                                    className="weekend-block"
                                                                >
                                                                    <Box display="flex" flex="1" flexDirection="column">
                                                                        <Box
                                                                            display="flex" height="100%"
                                                                            position="relative"
                                                                            flexDirection="column"
                                                                            justifyContent="center">
                                                                            {!day?.["is_work_day"] && !day?.["event_description"] &&
                                                                                <Box
                                                                                    className="weekend"
                                                                                    sx={{height: "100% !important"}}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                />
                                                                            }
                                                                            {!day?.["is_work_day"] && day?.["event_description"] &&
                                                                                <Box
                                                                                    sx={{height: (day.availability && day?.["is_work_day"]) ? "50% !important" : "100% !important"}}
                                                                                    className="holiday"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                    data-tooltip-content={day?.["event_description"]}
                                                                                    data-tooltip-id="tooltip"
                                                                                >
                                                                                    <Tooltip id="tooltip"/>
                                                                                    <Box className="ic-holiday"/>
                                                                                </Box>
                                                                            }
                                                                            {day?.["partial_event"] &&
                                                                                <Box
                                                                                    className={`holiday ${day.availability && day?.["is_work_day"] ? "height-half" : "height-full"}`}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                    data-tooltip-content={day?.["partial_event"]}
                                                                                    data-tooltip-id="tooltip"
                                                                                >
                                                                                    <Tooltip id="tooltip"/>
                                                                                    <Box className="ic-holiday"/>
                                                                                </Box>
                                                                            }
                                                                            {(day?.["daily_plannings"].length == 0 && day.availability && day?.["is_work_day"]) &&
                                                                                <Box
                                                                                    className={`day ${day?.["partial_event"] ? "day-half" : "day-full"}`}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center">
                                                                                    <SnoozeOutlined className="person-add"></SnoozeOutlined>
                                                                                </Box>
                                                                            }
                                                                            {day?.["daily_plannings"].map((daily_planning: any, index: number) => {
                                                                                return (
                                                                                    <div className="used-day" key={index}>
                                                                                        <Box
                                                                                            key={index}
                                                                                            className={`used-day ${
                                                                                                day.availability && day?.["daily_plannings"].length === 1 &&
                                                                                                day?.["is_work_day"] ? "day-half" : "day-full"}`}
                                                                                            display="flex"
                                                                                            flexDirection="row"
                                                                                            alignItems="center"
                                                                                            justifyContent="center"
                                                                                            sx={{
                                                                                                backgroundColor: daily_planning?.color ? daily_planning?.color : "#FFFFFF",
                                                                                            }}
                                                                                        >
                                                                                            {daily_planning?.markers && daily_planning?.markers.length > 0 &&
                                                                                                <Box
                                                                                                    className="marker"
                                                                                                    data-tooltip-content=""
                                                                                                    data-tooltip-id="empty_tooltip"
                                                                                                >!
                                                                                                    <Tooltip id="empty_tooltip"/>
                                                                                                </Box>
                                                                                            }
                                                                                            <Box
                                                                                                display="flex"
                                                                                                flexDirection="column"
                                                                                                alignItems="center"
                                                                                                sx={{margin: "16px"}}>
                                                                                                <Typography
                                                                                                    className="no-break-text txt-totals"
                                                                                                    sx={{color: daily_planning.font_color, marginBottom: "4px"}}>
                                                                                                    {
                                                                                                        daily_planning.is_npi == true ? "NPI" + " / " +
                                                                                                            daily_planning?.["total_realized"] : daily_planning?.["total_goal"] + " / " +
                                                                                                            daily_planning?.["total_realized"]
                                                                                                    }
                                                                                                </Typography>
                                                                                                <Typography className="txt-description" sx={{color: daily_planning.font_color}}>
                                                                                                    {daily_planning?.model}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        {day.availability && day?.["daily_plannings"].length == 1 && day?.["is_work_day"] &&
                                                                                            <Box
                                                                                                sx={{height: "50%"}}
                                                                                                className="day"
                                                                                                display="flex"
                                                                                                alignItems="center"
                                                                                                justifyContent="center"
                                                                                            >
                                                                                                <SnoozeOutlined className="person-add"></SnoozeOutlined>
                                                                                            </Box>
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );

};

export default DashboardPlanningComponent;
