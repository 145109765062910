import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../../../translate/translate";
import {Box, DialogActions, DialogTitle, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {BaseService} from "../../../../../../services/base-service";
import {URLS} from "../../../../../../services/app-urls";
import {Close} from "@mui/icons-material";
import {ErrorComponent} from "../../../../../../components/error/error.component";
import {toast} from "react-toastify";
import {ProductionLine} from "../../../../../../models/basic/production-line";
import {ActivePeopleLine} from "../../../../../../models/basic/active-people-line";


const DialogActivePeopleLineComponent = (props: any) => {
    const {open, onClose, activePeopleCount, activePeopleLineDate, activePeopleLine, weekTitle} = props;
    const lineService = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const service = new BaseService<ActivePeopleLine>(URLS.ACTIVE_PEOPLE_LINE);
    const [lines, setLines] = useState<ProductionLine[]>([]);
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));

    const handleClose = (event: any, reason: any, result: boolean) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(result);
    };

    const convertDateFormat = (date: string) => {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
    };

    const getLines = () => {
        lineService.clearParameters();
        lineService.addParameter("active", true);
        lineService.addParameter("ordering", "name");
        lineService.getAll().then((response: any) => {
            setLines(response["data"]);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const onSubmit = (values: any) => {
        const resultValue: any = {};

        Object.entries(values).forEach(([key, value]) => {
            const [day, line] = key.split(";");
            const convertedDay = convertDateFormat(day);
            if (!resultValue[convertedDay]) {
                resultValue[convertedDay] = {};
            }
            resultValue[convertedDay][line] = value;
        });
        const payload = {
            active_people_count: activePeopleCount.id,
            lines: resultValue
        };
        service.clearParameters();
        service.postFromListRoute(payload, "save_active_people_line").then(() => {
            toast.success(translate.t("successfully_updated"));
            handleClose(null, null, true);
        }).catch((error: any) => {
            ErrorComponent(error);
            handleClose(null, null, false);
        });
    };

    const updateValidationSchema = () => {
        const schema = Yup.object().shape(
            lines.reduce((schema: any, line) => {
                activePeopleLineDate.forEach((date: string) => {
                    schema[date + ";" + line.id] = Yup.number().min(0).required();
                });
                return schema;
            }, {})
        );
        setValidationSchema(schema);
    };

    const getInitialValues = () => {
        return lines.reduce((values: any, line) => {
            activePeopleLineDate.map((date: string, index: number) => {
                const matchingLine: any = Object.values(activePeopleLine[index]).find((lineItem: any) => date + ";" + lineItem.id === date + ";" + line.id);
                values[date + ";" + line.id] = matchingLine ? matchingLine["quantity"] : 0;
            });
            return values;
        }, {});
    };

    useEffect(() => {
        getLines();
    }, []);

    useEffect(() => {
        if (lines.length > 0) {
            updateValidationSchema();
        }
    }, [lines]);

    return (
        <div>
            <Dialog open={open} onClose={(event, reason) => handleClose(event, reason, false)} fullWidth={true} maxWidth={"xl"}>
                <DialogTitle sx={{backgroundColor: "primary.main"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                                {translate.t("active_people_line")}{weekTitle && " - " + weekTitle}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => handleClose(null, null, false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            validationSchema={validationSchema}
                            initialValues={getInitialValues()}
                            onSubmit={onSubmit}
                        >
                            {({isValid, values, errors, touched}) => (
                                <Form id="formGroup" autoComplete="off">
                                    <Box display="flex" flexDirection="row">
                                        {activePeopleLineDate.map((date: any, dateIndex: number) => (
                                            <Box display="flex" flex="1" flexDirection="column" key={date} padding="15px">
                                                <Typography variant="h6">
                                                    {date}
                                                </Typography>
                                                {lines.map((line: any, lineIndex: number) => (
                                                    <Grid item xs={12} md={6} lg={4} key={date + ";" + line.id} padding="5px">
                                                        <Field
                                                            as={TextField}
                                                            id={date + ";" + line.id}
                                                            name={date + ";" + line.id}
                                                            label={line.name}
                                                            type="number"
                                                            autoFocus={dateIndex == 0 && lineIndex == 0}
                                                            fullWidth
                                                            error={touched[date + ";" + line.id] && !!errors[date + ";" + line.id]}
                                                            InputProps={{
                                                                min: 0,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {touched[date + ";" + line.id] && !!errors[date + ";" + line.id] && (
                                                                            <div className={"required-field"}>
                                                                                {values[date + ";" + line.id] ? translate.t("greater_than_equal_0") : translate.t("required_field")}
                                                                            </div>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                        <Grid item>
                            <Button
                                className="button-options"
                                color="secondary"
                                onClick={() => handleClose(null, null, false)}
                                variant="contained">
                                {translate.t("cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="button-options"
                                color="success"
                                type="submit"
                                form="formGroup"
                                variant="contained">
                                {translate.t("confirm")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogActivePeopleLineComponent;
