import {Backdrop, Box, Paper, Typography} from "@mui/material";
import loading from "../../../assets/images/loading.gif";
import * as React from "react";
import {translate} from "../../translate/translate";

const Loading = (props: any) => {
    const {open} = props;

    return (
        <Backdrop
            sx={{backgroundColor: "rgba(0, 0, 0, .7)", zIndex: (theme) => theme.zIndex.modal + 1}} open={open}>
            <Paper elevation={6} sx={{ padding: "16px 42px 32px 42px", textAlign: "center" }}>
                <Box component={"img"} sx={{ height: 100, width: 100 }} alt="Logo P-Plan" src={loading} />
                <Typography component="h1" variant="h5" sx={{ fontSize: "16px" }}>
                    {translate.t("loading")}
                </Typography>
            </Paper>
        </Backdrop>
    );
};

export default Loading;
