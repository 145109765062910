import React, {useState} from "react";
import {Stepper, Step, StepLabel, Button, Box} from "@mui/material";
import ImportStockComponent from "../../basic/import-stock/import-stock.component";
import PlanningByDeliveryComponent from "../planning-by-delivery/planning-by-delivery.component";
import ImportMaterialTransitComponent from "../import-material-transit/import-material-transit.component";
import {translate} from "../../../translate/translate";

const steps = [translate.t("stock"), translate.t("import_material_transit"), translate.t("planning_by_delivery")];

const StepperExample = () => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
    const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

    const getStepContent = (step: any) => {
        if (step == 0) return <ImportStockComponent/>;
        if (step == 1) return <ImportMaterialTransitComponent/>;
        if (step == 2) return <PlanningByDeliveryComponent/>;
    };

    return (
        <Box sx={{width: "100%", height: "90%"}}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box sx={{mt: 2, mb: 2, height: "100%" }}>{getStepContent(activeStep)}</Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                {activeStep > 0 ? (
                    <Button
                        onClick={handleBack}
                        sx={{mr: 1, backgroundColor: "#FFFFFF"}}
                    >
                        {translate.t("previous")}
                    </Button>
                ) : <div></div>}
                {activeStep < steps.length - 1 && (
                    <Button
                        variant="contained"
                        onClick={handleNext}
                    >
                        {translate.t("next")}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default StepperExample;
