import React, {useEffect} from "react";
import {Field, Form, Formik} from "formik";
import Dialog from "@mui/material/Dialog";
import {translate} from "../../../../translate/translate";
import {DialogActions, DialogTitle, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {Assignment, Close} from "@mui/icons-material";
import {Demand} from "../../../../models/basic/demand";
import {URLS} from "../../../../services/app-urls";
import {BaseService} from "../../../../services/base-service";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import {MuiColorInput} from "mui-color-input";
import {toast} from "react-toastify";
import {ErrorComponent} from "../../../../components/error/error.component";
import {ProductionOrderDemand} from "../../../../models/basic/production-order-demand";


const DialogEditDemandComponent = (props: any) => {
    const {
        open,
        onClose,
        demand
    } = props;
    const service = new BaseService<Demand>(URLS.DEMAND);
    const productionOrderDemandService = new BaseService<ProductionOrderDemand>(URLS.PRODUCTION_ORDER_DEMAND);
    const [colorValue, setColorValue] = React.useState<string>("#000000");
    const [productionOrder, setProductionOrder] = React.useState<string>("");
    const [productionOrderDemand, setProductionOrderDemand] = React.useState<ProductionOrderDemand | null>(null);
    const [object, setObject] = React.useState(new Demand());
    const schema = Yup.object().shape({production_order: Yup.string().required()});

    useEffect(() => {
        getDemand();
    }, [demand]);

    const getDemand = () => {
        service.clearParameters();
        service.getById(demand.id).then((response) => {
            setObject(response.data);
            setColorValue(response.data.color);
            productionOrderDemandService.clearParameters();
            productionOrderDemandService.addParameter("demand", response.data.id);
            productionOrderDemandService.getAll().then((productionOrderDemand: any) => {
                if (productionOrderDemand.data && productionOrderDemand.data?.length > 0) {
                    setProductionOrder(productionOrderDemand.data[0].production_order);
                    setProductionOrderDemand(productionOrderDemand.data[0]);
                } else {
                    setProductionOrder("");
                    setProductionOrderDemand(null);
                }
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(false);
    };

    const handleChangeColor = (newColorValue: string) => {
        setColorValue(newColorValue);
    };

    const saveDemand = () => {
        service.clearParameters();
        object.color = colorValue;
        service.patch(object, object.id)
            .then(() => {
                toast.success(translate.t("successfully_updated"));
                productionOrder ? saveProductionOrder() : onClose(true);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const saveProductionOrder = () => {
        const payload = {
            "production_order": productionOrder,
            "demand": object.url
        };
        if (productionOrderDemand?.["id"]) {
            productionOrderDemandService.patch(payload as ProductionOrderDemand, productionOrderDemand?.["id"])
                .then(() => {
                    onClose(true);
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        } else {
            productionOrderDemandService.save(payload as ProductionOrderDemand)
                .then(() => {
                    onClose(true);
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex"
                                alignItems="center">
                                <Assignment fontSize="large" sx={{paddingRight: "5px"}}/>
                                {translate.t("edit_demand")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => onClose(false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <Formik
                        initialValues={object}
                        validationSchema={schema}
                        validateOnChange={true}
                        enableReinitialize
                        onSubmit={(values: Demand, {setSubmitting}) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 500);
                        }}>
                        {() => (
                            <Form autoComplete="off">
                                <Grid container paddingTop={2} direction="column" spacing={2}>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="production_order"
                                                label={translate.t("production_order")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={false}
                                                value={productionOrder}
                                                onChange={(e: any) => setProductionOrder(e.target.value)}
                                                disabled={object?.status === "IP"}
                                                InputProps={{
                                                    maxLength: 12
                                                }}
                                                InputLabelProps={{
                                                    shrink: Boolean(productionOrder)
                                                }}/>
                                        </Grid>
                                        <Grid item xs>
                                            <MuiColorInput
                                                name="color"
                                                id="color"
                                                label={translate.t("color")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                error={!colorValue}
                                                inputProps={{
                                                    maxLength: 7,
                                                }}
                                                format="hex"
                                                isAlphaHidden
                                                value={colorValue}
                                                onChange={handleChangeColor}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {!colorValue && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => onClose(false)}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                    <Button
                        className="button-options"
                        color="success"
                        onClick={() => saveDemand()}
                        variant="contained">
                        {translate.t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogEditDemandComponent;
