import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogContent,
    Grid,
    Typography
} from "@mui/material";
import {translate} from "../../translate/translate";
import {Close, ExpandMore} from "@mui/icons-material";
import {DateTimeFormat} from "../date/date-timeFormat";
import * as React from "react";
import {useEffect} from "react";
import {CheckFieldsComponent} from "./check-fields.component";
import {FieldsComponent} from "./fields.component";
import {HistoryResult} from "../../models/default/history-result";


const HistoryComponent = (props: any) => {
    const {
        idHistory,
        service,
        columns_history,
        columns_history_show,
        dateColumns,
    } = props;
    const [data, setData] = React.useState<HistoryResult[]>();

    useEffect(() => {
        if (idHistory != 0) {
            service.clearParameters();
            service.getHistory(idHistory)
                .then((response: { data: React.SetStateAction<HistoryResult[] | undefined>; }) => {
                    setData((response.data));
                });
        }
    }, [props.openHistory, idHistory]);

    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            return;
        }
    };

    const columnsHistoryShow = (column: string, index: number) => {
        return columns_history_show === undefined ||
        columns_history_show.length !==
        columns_history.length
            ?
            translate.t(column)
            :
            translate.t(columns_history_show[index]);
    };

    const isDate = (column: string): boolean => {
        return dateColumns === undefined
            ? false
            : dateColumns.includes(column);
    };

    const showFields = (row: any) => {
        return (
            <>
                {row.history_type == "+" && (
                    columns_history?.map((column: any, index: number) => (
                        row.changes.find((obj: any) =>
                            obj.field == column)?.new !== undefined && (
                            <Grid key={column} xs={12} item sx={{fontSize: 12}}>
                                <b style={{marginRight: 5}}>
                                    {columnsHistoryShow(column, index)}:</b>
                                {FieldsComponent(row.changes, "new", column, isDate(column))}
                            </Grid>
                        )
                    ))
                )
                }
                {row.history_type == "~" && (
                    columns_history?.map((column: any, index: number) => (
                        row.changes.find((obj: any) => obj.field ==
                            column)?.new !== undefined && (
                            <Grid key={column} xs={12} item sx={{fontSize: 12}}>
                                <b style={{marginRight: 15}}>
                                    {columnsHistoryShow(column, index)}:&nbsp;&nbsp;
                                    {FieldsComponent(row.changes, "old", column, isDate(column))}
                                </b>
                                {translate.t("modified_to")}
                                <b style={{marginLeft: 15}}>
                                    {FieldsComponent(row.changes, "new", column, isDate(column))}
                                </b>
                            </Grid>
                        )
                    ))
                )
                }
                {row.history_type == "-" && (
                    columns_history?.map((column: any, index: number) => (
                        row.changes.find((obj: any) =>
                            obj.field == column)?.new !== undefined && (
                            <Grid key={column} xs={12} item sx={{fontSize: 12}}>
                                <b style={{marginRight: 5}}>
                                    {columnsHistoryShow(column, index)}:
                                </b>
                                {FieldsComponent(row.changes, "new", column, isDate(column))}
                            </Grid>
                        )
                    ))
                )
                }
            </>
        );
    };

    return (
        <Dialog open={props.openHistory} onClose={handleClose}>
            <Grid container className={"header-history"}>
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t("history")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => {
                        props.handleCloseDialogHistory();
                    }}/>
                </Grid>
            </Grid>
            <DialogContent style={{padding: "16px 16px 16px 16px"}}>
                {data?.map((row: any, index) => (
                    CheckFieldsComponent(row, columns_history) && (
                        <Accordion key={index} className={"accordion-history"}>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                sx={{
                                    backgroundColor: "#eeeeee",
                                    flexDirection: "row-reverse",
                                    height: "5px"
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography sx={{fontSize: "12px", marginLeft: 1, marginRight: 2}}>
                                            <b style={{marginRight: 8}}>{row.history_user?.name}</b>
                                            {DateTimeFormat(String(row.history_date))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {row.history_type == "+" &&
                                            (
                                                <Button
                                                    size="small"
                                                    sx={{height: "20px", float: "right"}}
                                                    variant="contained"
                                                    color="success">
                                                    {translate.t("history_create")}
                                                </Button>
                                            )
                                        }
                                        {
                                            row.history_type == "~" &&
                                            (
                                                <Button
                                                    size="small"
                                                    sx={{height: "20px", float: "right"}}
                                                    variant="contained"
                                                    color="primary">
                                                    {translate.t("history_update")}
                                                </Button>
                                            )
                                        }
                                        {
                                            row.history_type == "-" &&
                                            (<Button
                                                size="small"
                                                sx={{height: "20px", float: "right"}}
                                                variant="contained"
                                                color="error">
                                                {translate.t("history_delete")}
                                            </Button>)
                                        }
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} sx={{
                                    backgroundColor: "#ffffff",
                                    paddingBottom: 2,
                                    paddingRight: 2
                                }}>
                                    {showFields(row)}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                ))
                }
            </DialogContent>
        </Dialog>
    );
};

export default HistoryComponent;
