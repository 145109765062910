import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Fab, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Product} from "../../../models/basic/product";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import TableActionComponent from "../../../components/table/table-action.component";
import {PaginatedResult} from "../../../models/default/paginated-result";
import {DailyProduction} from "../../../models/basic/daily-production";
import Select from "@mui/material/Select";
import moment from "moment/moment";
import {ErrorComponent} from "../../../components/error/error.component";
import {Segment} from "../../../models/basic/segment";
import {toast} from "react-toastify";
import {DateFormat} from "../../../components/date/date-timeFormat";
import {useKeepFilters} from "../../../utils/form-utils";

enum ReportType {
    PRODUCT = "P",
    SEGMENT = "S"
}

enum PeriodType {
    BY_DATE = "D",
    BY_WEEK_AND_YEAR = "W"
}


const ProducedQuantityComponent = (props: any) => {
    const {showFilters = true, namespace = ""} = props;
    const service = new BaseService<DailyProduction>(URLS.DAILY_PRODUCTION);
    const url = "/reports/daily_production/";
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const segmentService = new BaseService<Segment>(URLS.SEGMENT);

    const currentYear = moment().year();
    const currentWeek = moment().week();

    const [clearAutocomplete] = useState<boolean>(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [pagination, setPagination] = React.useState<PaginatedResult<any>>();
    const [dataSource, setDataSource] = React.useState<any>([]);
    const [showTable, setShowTable] = React.useState(false);

    const {searchParams, setFilterParameter} = useKeepFilters(namespace);

    const periodType = searchParams.get(`${namespace}`+"period_type") || PeriodType.BY_WEEK_AND_YEAR;
    const reportType = searchParams.get(`${namespace}`+"report_type") || ReportType.PRODUCT;
    const year = searchParams.get(`${namespace}`+"year") || "";
    const startWeek = searchParams.get(`${namespace}`+"start_week") || "";
    const endWeek = searchParams.get(`${namespace}`+"end_week") || "";
    const startDate = searchParams.get(`${namespace}`+"start_date") || DateFormat(new Date().toString(), "yyyy-MM-DD");
    const endDate = searchParams.get(`${namespace}`+"end_date") || DateFormat(new Date().toString(), "yyyy-MM-DD");
    const model = searchParams.get(`${namespace}`+"model") || "";
    const product = searchParams.get(`${namespace}`+"product") || "";
    const segment = searchParams.get(`${namespace}`+"segment") || "";

    useEffect(() => {
        if(!year || !startWeek || !endWeek) {
            setFilterParameter("year", currentYear);
            setFilterParameter("start_week", currentWeek);
            setFilterParameter("end_week", currentWeek);
        }
    }, []);

    const handleSelectProduct = (product: Product) => {
        setFilterParameter("product", product);
    };

    const handleSelectSegment = (segment: Segment) => {
        setFilterParameter("segment", segment);
    };

    const handleChangeReportType = (reportType: string) => {
        setShowTable(false);
        setDataSource([]);
        setPage(0);
        setRowsPerPage(10);
        setPagination(new PaginatedResult());
        setFilterParameter("report_type", reportType);
    };

    const handleChangePeriodType = (periodType: string) => {

        if (periodType === PeriodType.BY_DATE) {
            setFilterParameter("year", null);
            setFilterParameter("start_week", null);
            setFilterParameter("end_week", null);

            setFilterParameter("start_date", moment().startOf("month").format("YYYY-MM-DD"));
            setFilterParameter("end_date", moment().format("YYYY-MM-DD"));
        } else {
            setFilterParameter("year", currentYear);
            setFilterParameter("start_week", currentWeek);
            setFilterParameter("end_week", currentWeek);

            setFilterParameter("start_date", null);
            setFilterParameter("end_date", null);
        }

        setShowTable(false);
        setDataSource([]);
        setPage(0);
        setRowsPerPage(10);
        setPagination(new PaginatedResult());
        setFilterParameter("period_type", periodType);
    };

    // Use effects
    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        getProducedQuantity();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getProducedQuantity = () => {
        let isValidForm;
        if (periodType === PeriodType.BY_DATE) {
            isValidForm = startDate && endDate;
        } else {
            isValidForm = startWeek && endWeek && year;
        }

        if (isValidForm && reportType) {
            setTimeout(() => {
                service.clearParameters();

                if (periodType === PeriodType.BY_WEEK_AND_YEAR) {
                    service.addParameter("year", year);
                    service.addParameter("start_week", startWeek);
                    service.addParameter("end_week", endWeek);
                } else if(periodType === PeriodType.BY_DATE) {
                    service.addParameter("start_date", startDate);
                    service.addParameter("end_date", endDate);
                }
                service.addParameter("period_type", periodType);
                service.addParameter("report_type", reportType);
                service.addParameter("product_id", product ? product : "");
                service.addParameter("model", model ?? "");
                service.addParameter("segment_id", segment ? segment : "");
                service.addParameter("limit", rowsPerPage);
                service.addParameter("offset", (page * rowsPerPage));
                if (reportType === ReportType.PRODUCT) {
                    service.getFromListRoute("get_produced_quantity_by_product")
                        .then((response: any) => {
                            if (response.data.count > 0) {
                                setShowTable(true);
                            } else {
                                toast.warning(translate.t("empty_listing"));
                            }
                            setPagination(response.data);
                        })
                        .catch((error: any) => {
                            ErrorComponent(error);
                        })
                        .finally(() => {
                            setShowTable(true);
                        });
                } else if (reportType === ReportType.SEGMENT) {
                    service.getFromListRoute("get_produced_quantity_by_segment")
                        .then((response: any) => {
                            if (response.data.count > 0) {
                                setShowTable(true);
                            } else {
                                toast.warning(translate.t("empty_listing"));
                            }
                            setPagination(response.data);
                        })
                        .catch((error: any) => {
                            ErrorComponent(error);
                        })
                        .finally(() => {
                            setShowTable(true);
                        });
                }
            }, 500);
        }

    };

    const disabled = () => {
        if (periodType === PeriodType.BY_DATE) {
            return !startDate || !endDate;
        } else {
            return !startWeek || !endWeek || !year;
        }
    };

    // Render return
    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("produced_quantity")}
                </Typography>
            </div>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{
                                startWeek: "", endWeek: "", year: "", product: "",
                                report_type: ReportType.PRODUCT, model: "", start_date: "", end_date: "", period_type: "",
                            }}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={() => {
                                getProducedQuantity();
                            }}
                        >
                            {
                                ({values, touched}) => (
                                    <Form autoComplete="off">
                                        {showFilters && (
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item xs sm md lg>
                                                    <FormControl fullWidth>
                                                        <InputLabel className={"formControl"} htmlFor="select_by">
                                                            {translate.t("select_by")} *
                                                        </InputLabel>
                                                        <Field
                                                            variant={"outlined"}
                                                            label={translate.t("select_by")}
                                                            name="select_by"
                                                            component={Select}
                                                            autoFocus={false}
                                                            value={periodType}
                                                            values={values}
                                                            required={true}
                                                            error={touched.period_type && !periodType}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                                handleChangePeriodType(event.target.value as string)}>
                                                            <MenuItem id="week" value={PeriodType.BY_WEEK_AND_YEAR}>{translate.t("week")}</MenuItem>
                                                            <MenuItem id="date" value={PeriodType.BY_DATE}>{translate.t("date")}</MenuItem>
                                                        </Field>
                                                        {(touched.period_type && !periodType) && (
                                                            <div className={"required-field"}>
                                                                {translate.t("required_field")}
                                                            </div>
                                                        )}
                                                    </FormControl>
                                                </Grid>

                                                {periodType === PeriodType.BY_WEEK_AND_YEAR && (

                                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                as={TextField}
                                                                name="year"
                                                                label={translate.t("year")}
                                                                variant={"outlined"}
                                                                fullWidth
                                                                autoFocus={false}
                                                                required={true}
                                                                value={year}
                                                                values={values}
                                                                onChange={(event: any) => setFilterParameter("year", event.target.value as number)}
                                                                error={touched.year && !year}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {touched.year && !year && (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}/>
                                                        </FormControl>
                                                    </Grid>)}
                                                {periodType === PeriodType.BY_WEEK_AND_YEAR && (
                                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                as={TextField}
                                                                name="start_week"
                                                                label={translate.t("start_week")}
                                                                variant={"outlined"}
                                                                fullWidth
                                                                autoFocus={false}
                                                                required={true}
                                                                value={startWeek}
                                                                values={values}
                                                                onChange={(event: any) => setFilterParameter("start_week", event.target.value as number)}
                                                                error={touched.startWeek && !startWeek}
                                                                type="number"
                                                                InputProps={{
                                                                    min: 1,
                                                                    max: 53,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {touched.startWeek && !startWeek && (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}/>
                                                        </FormControl>
                                                    </Grid>
                                                )}


                                                {periodType === PeriodType.BY_WEEK_AND_YEAR && (
                                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                as={TextField}
                                                                name="end_week"
                                                                label={translate.t("end_week")}
                                                                variant={"outlined"}
                                                                fullWidth
                                                                autoFocus={false}
                                                                required={true}
                                                                value={endWeek}
                                                                values={values}
                                                                onChange={(event: any) => setFilterParameter("end_week", event.target.value as number)}
                                                                error={(touched.endWeek && !endWeek) || (touched.endWeek && startWeek && (endWeek! < startWeek))}
                                                                type="number"
                                                                InputProps={{
                                                                    min: 1,
                                                                    max: 53,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {touched.endWeek && !endWeek && (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            )}
                                                                            {touched.endWeek && startWeek && (endWeek! < startWeek) && (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_bigger_equal_than_start_week")}
                                                                                </div>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}/>
                                                        </FormControl>
                                                    </Grid>
                                                )}

                                                {periodType === PeriodType.BY_DATE && (
                                                    <Grid item xs sm md lg>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                as={TextField}
                                                                id="id_start_date"
                                                                name="start_date"
                                                                label={translate.t("start_date")}
                                                                required={true}
                                                                value={startDate}
                                                                values={values}
                                                                onChange={(event: any) => setFilterParameter("start_date", event.target.value as string)}
                                                                type="date"
                                                                fullWidth
                                                                error={(touched.start_date && !startDate)}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {touched.start_date && !startDate && (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                                {periodType === PeriodType.BY_DATE && (
                                                    <Grid item xs sm md lg>
                                                        <FormControl fullWidth>
                                                            <Field
                                                                as={TextField}
                                                                id="id_end_date"
                                                                name="end_date"
                                                                label={translate.t("end_date")}
                                                                required={true}
                                                                value={endDate}
                                                                values={values}
                                                                onChange={(event: any) => setFilterParameter("end_date", event.target.value as string)}
                                                                type="date"
                                                                fullWidth
                                                                error={(touched.end_date && !endDate)}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {touched.end_date && !endDate && (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                )}

                                                <Grid item xs sm md lg>
                                                    <FormControl fullWidth>
                                                        <InputLabel className={"formControl"} htmlFor="report_type">
                                                            {translate.t("report_type")} *
                                                        </InputLabel>
                                                        <Field
                                                            variant={"outlined"}
                                                            label={translate.t("report_type")}
                                                            name="report_type"
                                                            component={Select}
                                                            autoFocus={false}
                                                            value={reportType}
                                                            values={values}
                                                            required={true}
                                                            error={touched.report_type && !reportType}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                                handleChangeReportType(event.target.value as string)}>
                                                            <MenuItem id="product" value={ReportType.PRODUCT}>{translate.t("product")}</MenuItem>
                                                            <MenuItem id="segment" value={ReportType.SEGMENT}>{translate.t("segment")}</MenuItem>
                                                        </Field>
                                                        {(touched.report_type && !reportType) && (
                                                            <div className={"required-field"}>
                                                                {translate.t("required_field")}
                                                            </div>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                {(reportType === ReportType.PRODUCT) && (
                                                    <Grid item xs sm md lg>
                                                        <Field
                                                            as={TextField}
                                                            name="model"
                                                            label={translate.t("model")}
                                                            helperText={<ErrorMessage name="model"/>}
                                                            fullWidth
                                                            variant={"outlined"}
                                                            autoFocus={false}
                                                            value={model}
                                                            onChange={(event: React.ChangeEvent<{
                                                                value: unknown
                                                            }>) => setFilterParameter("model", event.target.value as string)}
                                                        />
                                                    </Grid>
                                                )}
                                                {(reportType === ReportType.PRODUCT) && (
                                                    <Grid item xs sm md lg>
                                                        <AutocompletePaginated
                                                            keepFilter={product}
                                                            display="model_and_name"
                                                            searchField="name"
                                                            label="product"
                                                            service={productService}
                                                            onSelectElement={handleSelectProduct}
                                                            required={false}
                                                            clear={clearAutocomplete}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs sm md lg>
                                                    <AutocompletePaginated
                                                        keepFilter={segment}
                                                        display="name"
                                                        searchField="name"
                                                        label="segment"
                                                        service={segmentService}
                                                        onSelectElement={handleSelectSegment}
                                                        required={false}
                                                        clear={clearAutocomplete}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        disabled={disabled()}
                                                        color="secondary">
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            {reportType === ReportType.PRODUCT && showTable && (
                <Paper variant="outlined" sx={{marginTop: "16px"}}>
                    <TablePaginationComponent
                        service={service}
                        state={dataSource}
                        displayedColumnsShow={["product_name", "product_model", "shift", "qty"]}
                        displayedColumns={["product_name", "product_model", "shift_name", "qty"]}
                        url={url}
                        count={pagination?.count}
                        next={pagination?.next}
                        previous={pagination?.previous}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        actionsComponent={TableActionComponent}
                        columnStyles={{1: "33%", 2: "33%", 3: "33%"}}
                    />
                </Paper>
            )}

            {reportType === ReportType.SEGMENT && showTable && (
                <Paper variant="outlined" sx={{marginTop: "16px"}}>
                    <TablePaginationComponent
                        service={service}
                        state={dataSource}
                        displayedColumnsShow={["segment_name", "shift", "qty"]}
                        displayedColumns={["segment_name", "shift_name", "qty"]}
                        url={url}
                        count={pagination?.count}
                        next={pagination?.next}
                        previous={pagination?.previous}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        actionsComponent={TableActionComponent}
                        columnStyles={{1: "50%", 2: "50%"}}
                    />
                </Paper>
            )}

        </Box>

    );
};

export default ProducedQuantityComponent;
