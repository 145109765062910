import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Chip, FormControl, Grid, InputLabel, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import EChartsReact, {EChartsEventInfo, useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ErrorComponent} from "../../../components/error/error.component";
import {LineSchedule} from "../../../models/deshboard/line-schedule";
import {translate} from "../../../translate/translate";
import {Form, Formik} from "formik";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {LineScheduleModel} from "../../../models/deshboard/line-schedule-model";
import {LineScheduleStation} from "../../../models/deshboard/line-schedule-station";
import Select from "@mui/material/Select";
import Loading from "../../../components/loading/loading";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initLineScheduleOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true}
        }
    },
    legend: {
        data: []
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            rotate: 45,
            interval: 0
        }
    },
    yAxis: {
        type: "value",
        name: "",
        axisLabel: {
            formatter: "{value}"
        }
    },
    series: []
};

const DashboardLineScheduleComponent = () => {
    const service = new BaseService<LineSchedule>(URLS.LINE_SCHEDULE);
    const [showBufferChart, setShowBufferChart] = useState<boolean>(false);
    const [groupedData, setGroupedData] = useState<any[]>([]);
    const {chartRef: bufferChartRef, setChartOption: setBufferChartOption, handleListenChartReady: handleBufferChartReady} = useChart();
    const modelService = new BaseService<LineScheduleModel>(URLS.LINE_SCHEDULE_MODEL);
    const stationService = new BaseService<LineScheduleStation>(URLS.LINE_SCHEDULE_STATION);
    const [selectedModels, setSelectedModels] = useState<LineScheduleModel[]>([]);
    const [selectedStations, setSelectedStations] = useState<LineScheduleStation[]>([]);
    const [clearAutocompleteModel, setClearAutocompleteModel] = useState<boolean>(false);
    const [clearAutocompleteStation, setClearAutocompleteStation] = useState<boolean>(false);
    const [wipVolumeModels, setWipVolumeModels] = useState<LineScheduleModel[]>([]);
    const [wipVolumeStations, setWipVolumeStations] = useState<LineScheduleStation[]>([]);
    const [bufferChartModels, setBufferChartModels] = useState<LineScheduleModel[]>([]);
    const [bufferChartStations, setBufferChartStations] = useState<LineScheduleStation[]>([]);
    const [selectionOption, setSelectionOption] = useState<string>("");
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        fetchAllConfigurations();
        getData();
    }, [selectedModels, selectedStations]);

    const fetchAllConfigurations = () => {
        service.getFromListRoute("get_all_conf")
            .then((response: any) => {
                setWipVolumeModels(response.data["wip_volume_models"] ?? []);
                setWipVolumeStations(response.data["wip_volume_stations"] ?? []);
                setBufferChartModels(response.data["buffer_chart_models"] ?? []);
                setBufferChartStations(response.data["buffer_chart_stations"] ?? []);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const setBufferChartValues = (data: Record<string, Record<string, number>>) => {
        const option = JSON.parse(JSON.stringify(initLineScheduleOption));
        const models = Object.keys(data);
        const modelTotals: Record<string, number> = {};

        models.forEach((model) => {
            modelTotals[model] = Object.values(data[model]).reduce((sum, value) => sum + value, 0);
        });

        const sortedModels = models.sort((a, b) => modelTotals[b] - modelTotals[a]);
        const stations = new Set<string>();
        sortedModels.forEach((model) => {
            Object.keys(data[model]).forEach((station) => {
                if (data[model][station] > 0) {
                    stations.add(station);
                }
            });
        });

        option.xAxis.data = sortedModels;
        option.series = Array.from(stations).map((station) => {
            const seriesData = sortedModels.map((model) => (data[model][station] > 0 ? data[model][station] : 0));

            return {
                name: station,
                type: "bar",
                stack: "total",
                data: seriesData,
            };
        });

        option.tooltip = {
            trigger: "axis",
            axisPointer: {
                type: "shadow"
            },
            formatter: (params: any) => {
                const sortedParams = params
                    .filter((item: any) => item.value > 0)
                    .sort((a: any, b: any) => b.value - a.value);

                const tooltipContent = sortedParams
                    .map((item: any) => `
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
                    <span style="display:inline-block;text-align:left; padding-right: 16px;">${item.seriesName}</span>
                    <strong style="float:right;">${item.value}</strong>`
                    ).join("<br/>");
                return `<div>${tooltipContent}</div>`;
            }
        };

        option.legend.show = false;

        setBufferChartOption(option, true);
        setShowBufferChart(true);
    };

    const getData = () => {
        setLoading(true);
        const modelIds = selectedModels.map((model) => model.id);
        const stationIds = selectedStations.map((station) => station.id);
        service.addParameter("model_list", modelIds.join(","));
        service.addParameter("station_list", stationIds.join(","));
        service.getFromListRoute("get_line_schedule_data")
            .then((response: any) => {
                setLoading(false);
                setBufferChartValues(response.data);
                setGroupedData([]);
            })
            .catch((error: any) => {
                setLoading(false);
                ErrorComponent(error);
            });
    };

    const handleChartClick = (params: any) => {
        const modelName = params.name as string;
        getGroupedDataByNextStation(modelName);
    };

    const getGroupedDataByNextStation = (modelName: string) => {
        const stationIds = selectedStations.map((station) => station.id);
        service.addParameter("station_list", stationIds.join(","));
        service.addParameter("model_name", modelName);
        service.getFromListRoute("get_grouped_data_by_next_station")
            .then((response: any) => {
                setGroupedData(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const onEvents: Record<string, EChartsEventInfo> = {
        click: {
            handler: handleChartClick,
        }
    };

    const handleDeleteModel = (modelToDelete: LineScheduleModel) => {
        setSelectedModels((models) => models.filter((model) => model.id !== modelToDelete.id));
    };

    const handleDeleteStation = (stationToDelete: LineScheduleStation) => {
        setSelectedStations((stations) => stations.filter((station) => station.id !== stationToDelete.id));
    };

    const onSubmit = () => {
        setTimeout(async () => {
            setClearAutocompleteModel(!clearAutocompleteModel);
            setClearAutocompleteStation(!clearAutocompleteStation);
            getData();
        }, 500);
    };

    const handleSelectionChange = (option: string) => {
        setSelectionOption(option);

        if (option === "WipVolume") {
            setSelectedModels(wipVolumeModels);
            setSelectedStations(wipVolumeStations);
        } else if (option === "BufferChart") {
            setSelectedModels(bufferChartModels);
            setSelectedStations(bufferChartStations);
        } else {
            setSelectedModels([]);
            setSelectedStations([]);
        }
    };

    return (
        <Grid>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("line_schedule")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{}}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={onSubmit}
                            validateOnChange={true}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs sm md lg>
                                                <FormControl fullWidth>
                                                    <InputLabel>{translate.t("dashboard")}</InputLabel>
                                                    <Select
                                                        value={selectionOption}
                                                        onChange={(event) => handleSelectionChange(event.target.value)}
                                                        label="selection_option"
                                                    >
                                                        <MenuItem value="">&nbsp;</MenuItem>
                                                        <MenuItem value="WipVolume">Wip Volume</MenuItem>
                                                        <MenuItem value="BufferChart">Buffer Chart</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs sm md lg>
                                                <AutocompletePaginated
                                                    display={["description"]}
                                                    searchField="description"
                                                    label="model"
                                                    service={modelService}
                                                    clear={clearAutocompleteModel}
                                                    onSelectElement={(model: LineScheduleModel) => {
                                                        if (!selectedModels.find((m) => m.id === model.id)) {
                                                            setSelectedModels([...selectedModels, model]);
                                                            setClearAutocompleteModel(!clearAutocompleteModel);
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs sm md lg>
                                                <AutocompletePaginated
                                                    display={["description"]}
                                                    searchField="description"
                                                    label="station"
                                                    service={stationService}
                                                    clear={clearAutocompleteStation}
                                                    onSelectElement={(station: LineScheduleStation) => {
                                                        if (!selectedStations.find((s) => s.id === station.id)) {
                                                            setSelectedStations([...selectedStations, station]);
                                                            setClearAutocompleteStation(!clearAutocompleteStation);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>

                {selectedModels.length > 0 && (
                    <Paper variant="outlined" style={{marginTop: "8px"}}>
                        <Box p={2} marginTop={2}>
                            <Stack direction="row" flexWrap="wrap" gap={1}>
                                {selectedModels.map((model) => (
                                    <Chip
                                        key={model.id}
                                        label={model.description}
                                        onDelete={() => handleDeleteModel(model)}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </Paper>
                )}

                {selectedStations.length > 0 && (
                    <Paper variant="outlined" style={{marginTop: "8px"}}>
                        <Box p={2} marginTop={2}>
                            <Stack direction="row" flexWrap="wrap" gap={1}>
                                {selectedStations.map((station) => (
                                    <Chip
                                        key={station.id}
                                        label={station.description}
                                        onDelete={() => handleDeleteStation(station)}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </Paper>
                )}
            </Grid>

            {showBufferChart && (
                <Grid container direction={"column"} gap={0} marginTop={1}>
                    <Grid item xs>
                        <Paper id={"chart-buffer"} sx={{padding: "16px"}}>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={bufferChartRef}
                                echarts={echarts}
                                onChartReady={handleBufferChartReady}
                                onEvents={onEvents}
                            />
                        </Paper>
                    </Grid>

                    {groupedData.length > 0 && (
                        <Grid item xs sx={{paddingTop: "16px"}}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography variant="h6" style={{color: "white"}}>{translate.t("next_station")}</Typography></TableCell>
                                            <TableCell><Typography variant="h6" style={{color: "white"}}>{translate.t("total")}</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupedData.map((row: any, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.next_station === "nan" ? "N/A" : row.next_station}</TableCell>
                                                <TableCell>{row.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );

};

export default DashboardLineScheduleComponent;
