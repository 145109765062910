import React, {useEffect} from "react";
import {Formik} from "formik";
import Dialog from "@mui/material/Dialog";
import {translate} from "../../../../translate/translate";
import {DialogActions, DialogTitle, Grid, Typography} from "@mui/material";
import {CheckCircle, Close} from "@mui/icons-material";
import {Demand, DEMAND_STATUS} from "../../../../models/basic/demand";
import {URLS} from "../../../../services/app-urls";
import {BaseService} from "../../../../services/base-service";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";


const DialogFinalizeDemandComponent = (props: any) => {
    const {
        open,
        onClose,
        demand
    } = props;
    const service = new BaseService<Demand>(URLS.DEMAND);
    const [object, setObject] = React.useState(new Demand());

    useEffect(() => {
        getDemand();
    }, [demand]);

    const getDemand = () => {
        service.clearParameters();
        service.getById(demand.id).then((response) => {
            setObject(response.data);
        });
    };

    const handleClose = (event: any, reason: any) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(false);
    };

    const finalizeDemand = () => {
        object.status = DEMAND_STATUS.FINALIZED;
        service.patch(object, object.id)
            .then(() => {
                toast.success(translate.t("successfully_updated"));
                onClose(true);
            }).catch((error) => {
                const {response} = error;
                if (response?.status === 400) {
                    const {data} = response;
                    const message_error = data.non_field_errors || data.detail || "error_required_field";
                    toast.error(translate.t(message_error));
                    return;
                }
                console.log(error);
            });
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xs"}>
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex"
                                alignItems="center">
                                <CheckCircle fontSize="large" sx={{paddingRight: "5px"}}/>
                                {translate.t("finalize_demand")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => onClose(false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <Formik
                        initialValues={object}
                        validateOnChange={true}
                        enableReinitialize
                        onSubmit={(values: Demand, {setSubmitting}) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 500);
                        }}>
                        <Typography sx={{justifyContent: "center", display: "flex", fontSize: "14px"}}>
                            {translate.t("info_finalize_demand")}
                        </Typography>
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => onClose(false)}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                    <Button
                        className="button-options"
                        color="success"
                        onClick={() => finalizeDemand()}
                        variant="contained">
                        {translate.t("finalize")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogFinalizeDemandComponent;
