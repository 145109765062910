import BaseModel from "../default/base-model";

export class CustomReportConfig extends BaseModel {
    name: string;
    user: string;
    has_data: boolean;

    constructor() {
        super();
        this.name = "";
        this.user = "";
        this.has_data = false;
    }
}
