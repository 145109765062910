import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../../../translate/translate";
import {Box, DialogActions, DialogTitle, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {BaseService} from "../../../../../../services/base-service";
import {URLS} from "../../../../../../services/app-urls";
import {Close} from "@mui/icons-material";
import {ErrorComponent} from "../../../../../../components/error/error.component";
import {Shift} from "../../../../../../models/basic/shift";
import {ActiveLineShift} from "../../../../../../models/basic/active-line-shift";
import {toast} from "react-toastify";


const DialogActiveLineShiftComponent = (props: any) => {
    const {open, onClose, activeLineCount, activeLineShiftDate, activeLineShift, weekTitle} = props;
    const shiftService = new BaseService<Shift>(URLS.SHIFT);
    const service = new BaseService<ActiveLineShift>(URLS.ACTIVE_LINE_SHIFT);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));

    const handleClose = (event: any, reason: any, result: boolean) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(result);
    };

    const convertDateFormat = (date: string) => {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
    };

    const getShifts = () => {
        shiftService.clearParameters();
        shiftService.addParameter("active", true);
        shiftService.addParameter("ordering", "name");
        shiftService.getAll().then((response: any) => {
            setShifts(response["data"]);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const onSubmit = (values: any) => {
        const resultValue: any = {};

        Object.entries(values).forEach(([key, value]) => {
            const [day, shift] = key.split(";");
            const convertedDay = convertDateFormat(day);
            if (!resultValue[convertedDay]) {
                resultValue[convertedDay] = {};
            }
            resultValue[convertedDay][shift] = value;
        });
        const payload = {
            active_line_count: activeLineCount.id,
            shifts: resultValue
        };

        service.clearParameters();
        service.postFromListRoute(payload, "save_active_line_shift").then(() => {
            toast.success(translate.t("successfully_updated"));
            handleClose(null, null, true);
        }).catch((error: any) => {
            ErrorComponent(error);
            handleClose(null, null, false);
        });
    };

    const updateValidationSchema = () => {
        const schema = Yup.object().shape(
            shifts.reduce((schema: any, shift) => {
                activeLineShiftDate.forEach((date: string) => {
                    schema[date + ";" + shift.id] = Yup.number().min(0).required();
                });
                return schema;
            }, {})
        );
        setValidationSchema(schema);
    };

    const getInitialValues = () => {
        return shifts.reduce((values: any, shift) => {
            activeLineShiftDate.map((date: string, index: number) => {
                const matchingShift: any = Object.values(activeLineShift[index]).find((shiftItem: any) => date + ";" + shiftItem.id === date + ";" + shift.id);
                values[date + ";" + shift.id] = matchingShift ? matchingShift["quantity"] : 0;
            });
            return values;
        }, {});
    };

    useEffect(() => {
        getShifts();
    }, []);

    useEffect(() => {
        if (shifts.length > 0 && activeLineShiftDate.length > 0) {
            updateValidationSchema();
        }
    }, [shifts]);

    return (
        <div>
            <Dialog open={open} onClose={(event, reason) => handleClose(event, reason, false)} fullWidth={true} maxWidth={"xl"}>
                <DialogTitle sx={{backgroundColor: "primary.main"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                                {translate.t("active_line_shift")}{weekTitle && " - " + weekTitle}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => handleClose(null, null, false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            validationSchema={validationSchema}
                            initialValues={getInitialValues()}
                            onSubmit={onSubmit}
                        >
                            {({isValid, values, errors, touched}) => (
                                <Form id="formGroup" autoComplete="off">
                                    <Box display="flex" flexDirection="row">
                                        {activeLineShiftDate.map((date: any, dateIndex: number) => (
                                            <Box display="flex" flex="1" flexDirection="column" key={date} padding="15px">
                                                <Typography variant="h6">
                                                    {date}
                                                </Typography>
                                                {shifts.map((shift: any, shiftIndex: number) => (
                                                    <Grid item xs={12} md={6} lg={4} key={date + ";" + shift.id} padding="5px">
                                                        <Field
                                                            as={TextField}
                                                            id={date + ";" + shift.id}
                                                            name={date + ";" + shift.id}
                                                            label={shift.name}
                                                            type="number"
                                                            autoFocus={dateIndex == 0 && shiftIndex == 0}
                                                            fullWidth
                                                            error={touched[date + ";" + shift.id] && !!errors[date + ";" + shift.id]}
                                                            InputProps={{
                                                                min: 0,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {touched[date + ";" + shift.id] && !!errors[date + ";" + shift.id] && (
                                                                            <div className={"required-field"}>
                                                                                {values[date + ";" + shift.id] ? translate.t("greater_than_equal_0") : translate.t("required_field")}
                                                                            </div>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Box>
                                        ))}
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                        <Grid item>
                            <Button
                                className="button-options"
                                color="secondary"
                                onClick={() => handleClose(null, null, false)}
                                variant="contained">
                                {translate.t("cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="button-options"
                                color="success"
                                type="submit"
                                form="formGroup"
                                variant="contained">
                                {translate.t("confirm")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogActiveLineShiftComponent;
