import {BaseService} from "../../../../services/base-service";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Product} from "../../../../models/basic/product";
import {Segment} from "../../../../models/basic/segment";
import Select from "@mui/material/Select";
import {Side} from "../../../../models/basic/side";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {ErrorComponent} from "../../../../components/error/error.component";
import {useKeepFilters} from "../../../../utils/form-utils";

const ProductItemComponent = () => {
    // URL Request
    const params = useParams();
    const navigate = useNavigate();
    const url = "/registration/product/";
    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    // Services
    const service = new BaseService<Product>(URLS.PRODUCT);
    const sideService = new BaseService<Side>(URLS.SIDE);
    const segmentService = new BaseService<Segment>(URLS.SEGMENT);

    // Hooks
    const [object, setObject] = React.useState(new Product());
    const [buttonValue, setButtonValue] = useState("");
    const [allSides, setAllSides] = useState<Side[]>([]);
    const [segment, setSegment] = useState<Segment>();
    const [clearAutocomplete, setClearAutocomplete] = useState<boolean>(false);

    // Schema
    const schema = Yup.object().shape({
        name: Yup.string().max(256, `${translate.t("exceeds_character_limit")}`).required(translate.t("required_field")),
        model: Yup.string().max(256, `${translate.t("exceeds_character_limit")}`),
        manual_or_smt: Yup.string().required(translate.t("required_field")),
        type: Yup.string().required(translate.t("required_field")),
        sides: Yup.array().required(translate.t("required_field")).min(1)
    });

    // Use effects
    useEffect(() => {
        if (params["action"] != "create") {
            service.clearParameters();
            service.addParameter("expand", ["segment"]);
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setSegment(response.data.segment);
                setObject(response.data);
            });
        }
    }, []);

    useEffect(() => {
        getSides();
    }, []);

    const handleChangeSegment = (segment: Segment) => {
        setSegment(segment);
    };

    const getSides = () => {
        sideService.clearParameters();
        sideService.addParameter("ordering", "id");
        sideService.getAll()
            .then((response: any) => {
                setAllSides(response.data);
            });
    };

    const translateSides = (selected: any) => {
        const sides:any[] = [];
        selected.map((s: any) => {
            const name:any = allSides.find(obj => s === obj.url)?.name;
            sides.push(translate.t(name));
        });

        return sides.join(", ");
    };

    const onSubmitProduct = (values: Product, {resetForm}: FormikHelpers<Product>) => {
        setTimeout(() => {
            const product = new Product();
            values.segment = segment?.url;
            values.side_product_list = values.sides;
            Object.assign(product, values);
            if (product.id) {
                service.patch(product, product.id).then(() => {
                    toast.success(translate.t("successfully_updated"));
                    if (buttonValue === "save") {
                        setObject(new Product());
                        resetForm();
                        setClearAutocomplete(!clearAutocomplete);
                    } else {
                        navigate(previousUrl);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            } else {
                service.save(product)
                    .then(() => {
                        toast.success(translate.t("successfully_registered"));
                        if (buttonValue === "save") {
                            setObject(new Product());
                            resetForm();
                            setClearAutocomplete(!clearAutocomplete);
                        } else {
                            navigate(previousUrl);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }, 500);
    };

    // Render return
    return (
        <section>
            <div className="floating-title floating-title-item" 
                style={{display: "flex", flexDirection: "row", alignItems: "center"}}
            >
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("product")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmitProduct}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => <Form autoComplete="off">
                                <Grid container spacing={2} direction={"row"}>
                                    <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 104,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <AutocompletePaginated
                                                    display="name"
                                                    searchField="name"
                                                    label="segment"
                                                    service={segmentService}
                                                    onSelectElement={handleChangeSegment}
                                                    element={segment}
                                                    filters={[{"active": true}]}
                                                    clear={clearAutocomplete}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="model"
                                                variant={"outlined"}
                                                fullWidth
                                                label={translate.t("code")}
                                                autoFocus={false}
                                                values={values}
                                                required={false}
                                                helperText={<ErrorMessage name="model"/>}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    required={true}
                                                    className={
                                                        touched.type && Boolean(errors.type) ? "error-required" : ""
                                                    }
                                                >
                                                    {translate.t("type")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="type"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    label={translate.t("type")}
                                                    error={touched.type && Boolean(errors.type)}
                                                    autoFocus={false}
                                                    required={true}
                                                    values={values}>
                                                    <MenuItem value="P">{translate.t("product")}</MenuItem>
                                                    <MenuItem value="C">{translate.t("component")}</MenuItem>
                                                </Field>
                                                {(touched.type && Boolean(errors.type)) && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    required={true}
                                                    className={
                                                        touched.sides && Boolean(errors.sides) ? "error-required" : ""
                                                    }
                                                >
                                                    {translate.t("sides")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="sides"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    multiple
                                                    label={translate.t("sides")}
                                                    error={touched.sides && Boolean(errors.sides)}
                                                    autoFocus={false}
                                                    required={true}
                                                    renderValue={(selected: any) => translateSides(selected)}
                                                    values={values}>
                                                    {allSides.map((side: any) => (
                                                        <MenuItem key={side.id} value={side.url} >
                                                            <Checkbox checked={values.sides.includes(side.url)}/>
                                                            <ListItemText primary={translate.t(side.name)}/>
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                                {(touched.sides && Boolean(errors.sides)) && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    required={true}
                                                    className={
                                                        touched.manual_or_smt && Boolean(errors.manual_or_smt) ? "error-required" : ""
                                                    }
                                                >
                                                    {translate.t("manual_or_smt")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="manual_or_smt"
                                                    label={translate.t("manual_or_smt")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    values={values}
                                                    error={touched.manual_or_smt && Boolean(errors.manual_or_smt)}
                                                    helperText={<ErrorMessage name="manual_or_smt"/>}
                                                    required={true}>
                                                    <MenuItem value="M">{translate.t("manual")}</MenuItem>
                                                    <MenuItem value="S">{translate.t("smt")}</MenuItem>
                                                </Field>
                                                {(touched.manual_or_smt && Boolean(errors.manual_or_smt)) && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction={"row"}>
                                        <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                            <Grid item>
                                                <Button
                                                    color="secondary"
                                                    component={NavLink}
                                                    to={previousUrl}
                                                    variant="outlined">
                                                    {translate.t("cancel")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="success"
                                                    variant="contained"
                                                    type="submit"
                                                    value="save_form"
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        setButtonValue(event.currentTarget.value);
                                                    }}
                                                    name="save_form"
                                                    disabled={!isValid || isValidating || !values.name}>
                                                    {translate.t("save_form")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="secondary"
                                                    variant="contained"
                                                    type="submit"
                                                    value="save"
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        setButtonValue(event.currentTarget.value);
                                                    }}
                                                    name="save"
                                                    disabled={!isValid || isValidating || !values.name}>
                                                    {translate.t("save_plus")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default ProductItemComponent;
