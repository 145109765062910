import * as React from "react";
import {useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {Field, Form, Formik} from "formik";
import Select from "@mui/material/Select";
import EChartsReact, {useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import * as Yup from "yup";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart, LineChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {OverallEquipmentEffectiveness} from "../../../models/basic/overall-equipment-effectiveness";
import {ErrorComponent} from "../../../components/error/error.component";

echarts.use([GridComponent, BarChart, LineChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true, title: translate.t("save")}
        }
    },
    legend: {
        data: ["OEE", "Meta"]
    },
    xAxis: [
        {
            type: "category",
            data: [],
            axisPointer: {
                type: "shadow"
            }
        }
    ],
    yAxis: [
        {
            type: "value",
            name: "OEE",
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [
        {
            name: "OEE",
            type: "bar",
            label: {
                show: true,
                formatter: "{c} %"
            },
            data: []
        },
        {
            name: "Meta",
            type: "line",
            label: {
                show: true,
                formatter: "{c} %"
            },
            data: []
        }
    ]
};


const OeeComponent = (props: any) => {
    const {showFilters = true, namespace = ""} = props;
    // Services
    const service = new BaseService<OverallEquipmentEffectiveness>(URLS.OEE);

    // Hooks
    const [report_type, setReportType] = useState<string>("M");
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [oeeWeek, setOeeWeek] = useState<string>("");
    const [oeeMonth, setOeeMonth] = useState<string>("");
    const [oeeYear, setOeeYear] = useState<string>("");
    const [oeeYearTarget, setOeeYearTarget] = useState<string>("");
    const [oeeMonthTarget, setOeeMonthTarget] = useState<string>("");
    const [showChart, setShowChart] = useState<boolean>(false);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();

    // Form
    const schema = Yup.object().shape({
        report_type: Yup.string().required(),
        year: Yup.string().required(),
    });

    const handleReportTypeChange = (value: any) => {
        setReportType(value);
    };

    const getReportData = () => {
        service.clearParameters();
        service.addParameter("chart_type", report_type);
        service.addParameter("year", year);
        service.getFromListRoute("get_oee_chart_data")
            .then((response: any) => {
                const option = JSON.parse(JSON.stringify(initOption));
                if (report_type === "M") {
                    option["xAxis"][0]["data"] = response["data"]["months"];
                } else {
                    option["xAxis"][0]["data"] = response["data"]["weeks"];
                }
                option["series"][0]["data"] = response["data"]["oee"];
                option["series"][1]["data"] = response["data"]["goals"];
                setOeeWeek(response["data"]["title"]["actual_week"]);
                setOeeMonth(response["data"]["title"]["actual_month"]);
                setOeeYear(response["data"]["title"]["actual_year"]);
                setOeeMonthTarget(response["data"]["title"]["month_target"]);
                setOeeYearTarget(response["data"]["title"]["year_target"]);
                setChartOption(option);
                setShowChart(true);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Render return
    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("OEE")}
                </Typography>
            </div>

            <Grid item xs>
                <Paper variant="outlined" sx={{marginBottom: "20px"}}>
                    <Box p={2}>
                        <Formik
                            initialValues={{downtimeReason: "", start_date: "", end_date: "", report_type: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            validateOnMount={true}
                            enableReinitialize
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    getReportData();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => {
                                    return (
                                        <Form autoComplete="off">
                                            {showFilters && (
                                                <Grid alignItems="center" justifyContent={"flex-start"} container spacing={2} direction={"row"}>
                                                    <Grid item xs>
                                                        <FormControl fullWidth>
                                                            <InputLabel className={"formControl"} htmlFor="report_type">
                                                                {translate.t("report_type")} *
                                                            </InputLabel>
                                                            <Field
                                                                variant={"outlined"}
                                                                defaultValue="M"
                                                                label={translate.t("report_type")}
                                                                name="report_type"
                                                                component={Select}
                                                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleReportTypeChange(event.target.value as string)}
                                                            >
                                                                <MenuItem value="M">{translate.t("monthly")}</MenuItem>
                                                                <MenuItem value="W">{translate.t("weekly")}</MenuItem>
                                                            </Field>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Field
                                                            as={TextField}
                                                            type="number"
                                                            name="year"
                                                            label={translate.t("year")}
                                                            fullWidth
                                                            variant={"outlined"}
                                                            autoFocus={false}
                                                            required={true}
                                                            value={year}
                                                            InputProps={{
                                                                maxLength: 4,
                                                            }}
                                                            onChange={(event: React.ChangeEvent<{
                                                                value: unknown
                                                            }>) => setYear(event.target.value as number)}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Fab
                                                            size="small"
                                                            color="secondary"
                                                            disabled={!report_type}
                                                            onClick={getReportData}>
                                                            <Search/>
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Form>
                                    );
                                }}
                        </Formik>
                    </Box>
                </Paper>

                {showChart && (
                    <Grid container direction={"column"} gap={0}>
                        <Grid item xs>
                            <Box display="flex" gap={2}>
                                <Paper className={"card-paper"} variant="outlined" square>
                                    <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("oee_one") + ":"}</Typography>
                                    <Typography variant={"h4"}>{oeeWeek + " %"}</Typography>
                                </Paper>
                                <Paper className={"card-paper"} variant="outlined" square>
                                    <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("monthly_oee") + ":"}</Typography>
                                    <Typography variant={"h4"}>{oeeMonth + " %"}</Typography>
                                </Paper>
                                <Paper className={"card-paper"} variant="outlined" square>
                                    <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("year_oee") + ":"}</Typography>
                                    <Typography variant={"h4"}>{oeeYear + " %"}</Typography>
                                </Paper>
                                <Paper className={"card-paper"} variant="outlined" square>
                                    <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("month_target") + ":"}</Typography>
                                    <Typography variant={"h4"}>{oeeMonthTarget + " %"}</Typography>
                                </Paper>
                                <Paper className={"card-paper"} variant="outlined" square>
                                    <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("year_target") + ":"}</Typography>
                                    <Typography variant={"h4"}>{oeeYearTarget + " %"}</Typography>
                                </Paper>
                            </Box>
                        </Grid>

                        <Grid item xs>
                            <Paper id={"chart"} sx={{padding: "16px"}}>
                                <EChartsReact
                                    autoResize={true}
                                    style={{width: "100%", height: 500,}}
                                    ref={chartRef}
                                    echarts={echarts}
                                    onChartReady={handleListenChartReady}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default OeeComponent;
