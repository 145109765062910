import * as React from "react";
import {useEffect, useState} from "react";
import reportComponentMap from "../../../dto/reports-component-map";
import {ErrorComponent} from "../../../components/error/error.component";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {useParams} from "react-router-dom";
import {CustomReportDetail} from "../../../models/basic/custom-report-detail";
import {translate} from "../../../translate/translate";

const CustomReportViewComponent = () => {
    const params = useParams();
    const [tabConfig, setTabConfig] = useState<CustomReportDetail[]>([]);

    const service = new BaseService<CustomReportDetail>(URLS.CUSTOM_REPORT_DETAIL);
    const [activeTab, setActiveTab] = useState(0);
    const [showFilters, setShowFilters] = useState(true);

    useEffect(() => {
        getUrls();
    }, []);

    const getUrls = () => {
        const idCustomReportConfig = parseInt(params["action"] as string);
        service.clearParameters();
        service.addParameter("custom_report_config", idCustomReportConfig);
        service.addParameter("ordering", "order");
        service.getAll()
            .then((response: any) => {
                setTabConfig(processUrls(response.data));
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const extractUrlPath = (url: string) => {
        const match = url.match(/(?:\/reports|\/dashboard)(\/[^\s?]+)/);
        return match ? match[0] : null;
    };

    const processUrls = (objects: any) => {
        return objects
            .map((obj: any) => {
                const extractedUrl = extractUrlPath(obj.url);
                return extractedUrl ? { ...obj, url: extractedUrl } : null;
            })
            .filter((obj: any) => obj && reportComponentMap[obj.url]);
    };

    return (
        <div>
            {tabConfig.length === 0 ? (
                <div>{translate.t("no_records_found")}</div>
            ) : (
                <>
                    <div style={{marginBottom: "10px"}}>
                        <label>
                            <input
                                type="checkbox"
                                checked={showFilters}
                                onChange={() => setShowFilters(!showFilters)}
                            />
                            {translate.t("show_filters")}
                        </label>
                    </div>

                    <div style={{display: "flex", borderBottom: "1px solid #ccc"}}>
                        {tabConfig.map((urlObj, index) => (
                            <button
                                key={index}
                                style={{
                                    padding: "10px",
                                    cursor: "pointer",
                                    backgroundColor: activeTab === index ? "#007BFF" : "#f1f1f1",
                                    color: activeTab === index ? "white" : "black",
                                    border: "none",
                                    borderBottom: activeTab === index ? "2px solid #0056b3" : "none",
                                }}
                                onClick={() => setActiveTab(index)}
                            >
                                {urlObj.name}
                            </button>
                        ))}
                    </div>

                    <div style={{marginTop: "20px", padding: "10px"}}>
                        {tabConfig.map((urlObj, index) => (
                            <div key={index} style={{display: activeTab === index ? "block" : "none"}}>
                                {React.createElement(
                                    reportComponentMap[urlObj.url], {showFilters: showFilters, namespace: `${urlObj.name}_`} as React.ComponentProps<any>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}

        </div>
    );
};

export default CustomReportViewComponent;
