import {useSearchParams} from "react-router-dom";

export const useKeepFilters = (namespace = "") => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getQueryParamsObject = () => {
        const entries = searchParams.entries();
        const result: Record<string, any> = {};
        const entriesArray = Array.from(entries);
        for (const [key, value] of entriesArray) {
            if (key.startsWith(`${namespace}`)) {
                result[key.replace(`${namespace}`, "")] = value;
            }
        }
        return result;
    };

    const setFilterParameter = (key: string, value: any, field = "id") => {
        if (typeof value === "object") {
            setSearchParams(params => {
                params.set(`${namespace}${key}`, value ? String(value[field]) : "");
                return params;
            }, {replace: true});
        } else {
            setSearchParams(params => {
                params.set(`${namespace}${key}`, value);
                return params;
            }, {replace: true});
        }
    };

    return {
        getQueryParamsObject,
        setFilterParameter,
        searchParams
    };
};
